import { Component, EventEmitter, HostListener, OnInit, Output, inject } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { AppStateService } from '@app/shared/services/app-state.service';
import { KEY_EVENTS } from '@app/shared/constants/key-event-enums';

@Component({
  selector: 'app-start-button',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: './start-button.component.html',
  styleUrls: ['./start-button.component.scss'],
})
export class StartButtonComponent implements OnInit {
  @Output() playButtonClicked = new EventEmitter<void>();

  protected playButtonImage = '';
  protected playButtonCursorImage = '';

  private _appStateService = inject(AppStateService);

  ngOnInit() {
    // based upon level, we show different color buttons
    this.playButtonImage = `/assets/play-button-${this._appStateService.level}.svg`;
    this.playButtonCursorImage = `/assets/cursor-click-black.svg`;
  }

  onPlayClick() {
    this.playButtonClicked.emit();
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === KEY_EVENTS.SpaceBar || event.key === KEY_EVENTS.Enter) {
      this.onPlayClick();
    }
  }
}
