import { Injectable, inject } from '@angular/core';
import { PUZZLE_STATUS } from '@app/shared/constants/session-enums';
import { AppStateService } from './app-state.service';
import { IActivitySessionResume, IMistake, IPuzzle } from '../types/activity-session-interface';
import { APP_EVENT_AREAS } from '../constants/app-event-areas';

export enum SCORE_RANGE {
  HIGH,
  MEDIUM,
  LOW,
  UNKNOWN,
}

@Injectable({
  providedIn: 'root',
})
export class ScoringService {
  private _currentScore = 0.0; /// in percentage
  private _perfectScore = 0;
  private _numMistakes = 0;
  private _puzzleStartTime: number;
  private _currentPuzzle: IPuzzle;
  private _puzzlesPassed = 0;
  private _puzzlesTaken = 0;
  private _selfCorrections = 0;
  private _maxTries = 2; // 2 is default
  private _numTries = 0;
  private _selfCorrected = false;

  private _appStateService = inject(AppStateService);

  constructor() { }

  startPuzzle(puzzleIndex: number) {
    this._puzzleStartTime = new Date().getTime();
    this._currentPuzzle = {
      index: puzzleIndex,
      result: PUZZLE_STATUS.UNKNOWN,
      duration: 0,
      selfCorrected: false,
      mistakes: [],
    };

    this._appStateService.db('Start Puzzle', this._currentPuzzle);
    this.updateScoreComponent();
  }

  endPuzzle(result: PUZZLE_STATUS) {
    if (!this._currentPuzzle) {
      return this._currentPuzzle; // not tracking
    }
    this._currentPuzzle.duration = (new Date().getTime() - this._puzzleStartTime) / 1000 / 60; // milliseconds to minutes
    this._currentPuzzle.duration = parseFloat(this._currentPuzzle.duration.toFixed(2));

    this._currentPuzzle.result = result;
    this._currentPuzzle.selfCorrected = this._selfCorrected || (this._currentPuzzle.mistakes.length > 0 && result === PUZZLE_STATUS.PASS); // They recovered

    if (result === PUZZLE_STATUS.PASS) {
      this._puzzlesPassed++;
    }
    this._puzzlesTaken++;

    if (this._currentPuzzle.selfCorrected) {
      this._selfCorrections++;
    }

    this._appStateService.db('End Puzzle', this._currentPuzzle);
    this.updateScoreComponent();
    return this._currentPuzzle;
  }

  setPerfectScore(score: number) {
    this._perfectScore = score;
    this._appStateService.db('Set Perfect Score', this._perfectScore);
    this.updateScoreComponent();
  }

  setMaxTries(tries: number) {
    if(tries === 0) tries = 2;
    this._maxTries = tries;
    this._appStateService.db('Set Max Tries', this._maxTries);
    this.updateScoreComponent();
  }

  setNumTries(tries: number) {
    this._numTries = tries;
    this._appStateService.db('Set Num Tries', this._numTries);
    this.updateScoreComponent();
  }

  setSelfCorrected(selfCorrected: boolean) {
    this._selfCorrected = selfCorrected;
    this.updateScoreComponent();
  }

  addMistake() {
    this._numMistakes++;
    this._appStateService.db('Add Mistake', this._numMistakes);
    this.updateScoreComponent();
  }

  addUserMistakeDetails(mistake: IMistake) {
    this._currentPuzzle.mistakes.push(mistake);
    this._appStateService.db('Add Mistake Details', mistake);
    this.updateScoreComponent();
  }

  resetMistakes() {
    this._numMistakes = 0;
    this._appStateService.db('Reset Mistakes');
  }

  resetSelfCorrected() {
    this._selfCorrected = false;
  }

  reset() {
    this.resetMistakes();
    this._puzzlesTaken = 0;
    this._puzzlesPassed = 0;
    this._selfCorrections = 0;
    this.updateScoreComponent();
  }

  resumeScore(session: IActivitySessionResume) {
    this.resetMistakes();

    const sessionPuzzles = JSON.parse(session.puzzlesJSON);

    this._puzzlesTaken = sessionPuzzles.length;
    this._puzzlesPassed = sessionPuzzles.filter((p) => p.result === PUZZLE_STATUS.PASS).length;
    this._selfCorrections = sessionPuzzles.filter((p) => p.selfCorrected).length;
    this._currentScore = session.score;
  }

  get mistakeCount() {
    return this._numMistakes;
  }

  get perfectScore() {
    return this._perfectScore;
  }

  get currentScore() {
    return this._currentScore;
  }

  get puzzlesPassed() {
    return this._puzzlesPassed;
  }

  get puzzlesTaken() {
    return this._puzzlesTaken;
  }

  get selfCorrections() {
    return this._selfCorrections;
  }

  get maxTries() {
    return this._maxTries;
  }

  get numTries() {
    return this._numTries;
  }

  get selfCorrected() {
    return this._selfCorrected;
  }

  calculateScore() {
    this._currentScore = Math.round((this._puzzlesPassed / this._puzzlesTaken) * 100);
    this._currentScore = this._currentScore < 0 || Number.isNaN(this._currentScore) ? 0 : this._currentScore;
    this._appStateService.db('Calculate Score', this._currentScore);
    this.updateScoreComponent();

    return this._currentScore;
  }

  getScoreRange() {
    let range: SCORE_RANGE;
    // based upon score range...
    if (this._currentScore >= 0 && this._currentScore <= 59) {
      range = SCORE_RANGE.LOW;
    } else if (this._currentScore >= 60 && this._currentScore <= 79) {
      range = SCORE_RANGE.MEDIUM;
    } else {
      range = SCORE_RANGE.HIGH;
    }
    this.updateScoreComponent();
    return range;
  }

  updateScoreComponent() {
    this._appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.SHOW_SCORE,
    });
  }
}
