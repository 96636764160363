import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SoundService } from '@app/shared/services/sound.service';
import { LetterPanelComponent } from '../letter-panel/letter-panel.component';
import { ILetterPanel } from '../letter-panel/types/letter-panel-interface';
import { SOUND_NAMES } from '@app/shared/constants/sound-enums';

@Component({
  selector: 'app-choice-bank',
  standalone: true,
  templateUrl: './choice-bank.component.html',
  styleUrl: './choice-bank.component.scss',
  imports: [CommonModule, LetterPanelComponent],
})
export class ChoiceBankComponent {
  @Input() choices: ILetterPanel[];
  @Input() validatingPuzzle: boolean;
  @Output() chosenPanel = new EventEmitter<any>();



  constructor(private _soundService: SoundService) {}

  onLetterChoiceClicked(selectedPanel: ILetterPanel) {
    if(this.validatingPuzzle){
      return;
    }
    this._soundService.playSound(SOUND_NAMES.Click);

    this.chosenPanel.emit(selectedPanel);
  }
}
