<div class="load-carousel">
  <div class="rotator">
    <div class="carousel" #carousel [ngClass]="'l' + currentLevel">
      <div
        class="carousel-cell shadow-4"
        #cell
        *ngFor="let card of this.cards; let idx = index"
        [ngClass]="{
        active: card.active,
        }"
      >
        <div class="content">
          {{ card.content }}
        </div>
      </div>
    </div>

    <!-- Conditionally show the microphone or arrow based on recording status -->
    <button
      *ngIf="isRecording && totalCardsShown <= cards.length"
      class="mic button image-button"
      (click)="doRecord()"
      aria-label="Record"
      pTooltip="Record"
      tooltipPosition="left"
    >
      <img [src]="micButtonImage" height="71" width="106" alt="microphone icon" aria-hidden="true" />
    </button>

    <button
      *ngIf="!isRecording && totalCardsShown <= cards.length"
      class="arrow right button image-button"
      (click)="rotate(1)"
      aria-label="Next"
      pTooltip="Next"
      tooltipPosition="left"
    >
      <img [ngSrc]="rightButtonImage" height="71" width="106" alt="right arrow" aria-hidden="true" />
    </button>
  </div>
</div>
