import { DestroyRef, inject, Injectable } from '@angular/core';
import { catchError, combineLatest, lastValueFrom, map, Observable, of, tap } from 'rxjs';
import { Router } from '@angular/router';
import { APP_ROUTES } from '@app/app-routing.module';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';
import { AppStateService } from '@app/shared/services/app-state.service';

import { IActivityData } from '@app/shared/types/activity-data.interface';
import { API_ENDPOINTS } from '@app/shared/config/endpoints';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IActivityRegistry } from '../types/activity-registry.interface';
import { IValidateActivitySession } from '../types/activity-session-interface';

export interface IActivityRouteParams {
  level: number;
  activityId: string;
  unit: number;
  week: number;
  assignmentId: string;
  activitySessionId: string;
  previewToken: string;
}

export interface ILinkValidationResponse {
  activityRegistry: IActivityRegistry;
  activityData: IActivityData;
}

@Injectable({ providedIn: 'root' })
export class RoutingService {
  private _router = inject(Router);
  private _http = inject(HttpClient);
  private _appStateService = inject(AppStateService);
  private _destroyRef = inject(DestroyRef);

  redirectToPageNotFound() {
    this._router.navigate([APP_ROUTES.NOT_FOUND]);
  }

  goToActivity(id: string) {
    if (id) {
      this._router.navigate(['activities', id]);
    }
  }

  validateParams(params: IActivityRouteParams): Observable<boolean> {
    const isValid = !!(params.activityId && params.unit && params.week && params.assignmentId && params.activitySessionId);
    if (!isValid) {
      return of(false);
    }

    const url = API_ENDPOINTS.validateUrl;

    const payload: IValidateActivitySession = {
      activitySessionId: params.activitySessionId,
      activityId: params.activityId,
      assignmentId: params.assignmentId,
      unit: params.unit,
      week: params.week,
      level: params.level,
    };

    //only call the validator if activities-bypass is
    //not present in the url
    if (!this.isActivitiesBypass()) {
      this.validateUrl(payload).subscribe((res) => {
        if (!res) this.redirectToPageNotFound();
      });
    }

    if (environment.MOCKS_DIR) {
      this.validateUrlMock(payload);

      const activityRegistry$ = this._http
        .get<IActivityRegistry[]>(`${environment.MOCKS_DIR}/app-registry/registry-l${params.level}.json`)
        .pipe(map((data) => data.find((item) => item.id === params.activityId)));

      const activityDataset$ = this._http
        .get<any>(`${environment.MOCKS_DIR}/activity-datasets/dataset-a${params.activityId}-l${params.level}.json`)
        .pipe(
          // transform keys to lowercase (backend would normally do this)
          map((data) =>
            data.map((item) => {
              const newItem = {};
              Object.keys(item).forEach((key) => {
                newItem[key.charAt(0).toLowerCase() + key.slice(1)] = item[key];
              });
              return newItem;
            })
          ),
          map((res) => res.filter((item) => item.unit === +params.unit && item.week === +params.week))
        );
      return combineLatest([activityRegistry$, activityDataset$]).pipe(
        tap(([registry, dataset]) => {
          this._appStateService.appEvent$.next({
            area: APP_EVENT_AREAS.SET_ACTIVITY_REGISTRY,
            activityRegistry: registry,
          });

          this._appStateService.appEvent$.next({
            area: APP_EVENT_AREAS.SET_ACTIVITY_DATASET,
            dataset,
          });
        }),
        map((val) => !!val)
      );
    } else {
      return this._http.post<ILinkValidationResponse>(url, payload).pipe(
        tap((res: ILinkValidationResponse) => {
          this._appStateService.appEvent$.next({
            area: APP_EVENT_AREAS.SET_ACTIVITY_DATASET,
            dataset: res.activityData.activityDatasets,
          });
          this._appStateService.appEvent$.next({
            area: APP_EVENT_AREAS.SET_ACTIVITY_REGISTRY,
            activityRegistry: res.activityRegistry,
          });
          this.setAuthCookie();
        }),
        map((val) => !!val),
        catchError(() => {
          return of(false);
        })
      );
    }
  }

  private isActivitiesBypass(): boolean {
    return window.location.href.includes('activities-bypass') || !!this._appStateService.persistedUrlParams['token'];
  }

  validateUrl(payload: IValidateActivitySession) {
    const url = API_ENDPOINTS.validateSessionData;
    return this._http.post<boolean>(url, payload).pipe(takeUntilDestroyed(this._destroyRef));
  }

  async setAuthCookie() {
    const url = API_ENDPOINTS.setXsrf;
    await lastValueFrom(this._http.get(url));
  }

  async validateUrlMock(payload: IValidateActivitySession) {
    const url = API_ENDPOINTS.validateUrl;

    this._http
      .post<any>(url, payload)
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: () => {
          this.setAuthCookie();
        },
      });
  }
}
