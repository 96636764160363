<div id="wlt-activity" class="wlt-activity" *ngIf="this.activityReady">
  <div class="game-area">
    <!-- PT 0 Basic -->
    <ng-container *ngIf="this.puzzleType === puzzleTypeEnum.Basic">
      <app-bwe-puzzle-type-0 [currentBuildEchoDataset]="currentBuildEchoDataset" [expressions]="expressions"
        (puzzleComplete)="onPuzzleComplete()" (subPartComplete)="incrementProgress($event)">
      </app-bwe-puzzle-type-0>
    </ng-container>

    <!-- PT 1 Spelling Option -->
    <ng-container *ngIf="this.puzzleType === puzzleTypeEnum.SpellingOption">
      <app-bwe-puzzle-type-1 [currentBuildEchoDataset]="currentBuildEchoDataset" [expressions]="expressions"
        (puzzleComplete)="onPuzzleComplete()" (subPartComplete)="incrementProgress($event)">
      </app-bwe-puzzle-type-1>
    </ng-container>

    <!-- PT 2 Doubling Rules -->
    <ng-container *ngIf="this.puzzleType === puzzleTypeEnum.Doubling">
      <app-bwe-puzzle-type-2 [currentBuildEchoDataset]="currentBuildEchoDataset" [expressions]="expressions"
        (puzzleComplete)="onPuzzleComplete()" (subPartComplete)="incrementProgress($event)">
      </app-bwe-puzzle-type-2>
    </ng-container>

    <!-- PT 3 Suffix Rules Rules -->
    <ng-container *ngIf="this.puzzleType === puzzleTypeEnum.Suffix">
      <app-bwe-puzzle-type-3 [currentBuildEchoDataset]="currentBuildEchoDataset" [expressions]="expressions"
        (puzzleComplete)="onPuzzleComplete()" (subPartComplete)="incrementProgress($event)">
      </app-bwe-puzzle-type-3>
    </ng-container>
  </div>

  <div class="progress-bar-area" [ngClass]="{
      visible: !activitySolved && displayPB,
      hidden: activitySolved || !displayPB
    }">
    <app-progress-bar
    [totalPuzzles]="totalPuzzles">

    </app-progress-bar>
  </div>
</div>