import { Component, DestroyRef, HostListener, inject, OnInit, ViewChild } from '@angular/core';
import { distinctUntilChanged, filter, fromEvent, Observable, startWith, switchMap, take, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IAppEvent } from '@app/shared/types/app-event-interface';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';
import { AppStateService } from '@app/shared/services/app-state.service';
import { SoundService } from '@app/shared/services/sound.service';
import { DELAY } from '@app/shared/constants/delay-enums';
import { LoadingService } from '@app/shared/services/loading.service';
import { AppSettingsService } from '@app/shared/services/app-settings.service';
import { IAppSettings } from '@app/shared/types/app-settings-interface';
import { ActivatedRoute, Params } from '@angular/router';

import { NarratorComponent } from '@app/shared/components/narrator/narrator.component';
import { ActivityRegistryService } from '@app/shared/services/activity-registry.service';
import { IActivityRegistry } from '@app/shared/types/activity-registry.interface';
import { DeviceOrientation, getDeviceOrientation } from '@app/shared/helpers/device-orientation';
import { ConfirmationService } from 'primeng/api';
import { KEY_EVENTS } from '@app/shared/constants/key-event-enums';

@Component({
  selector: 'app-activity-layout',
  templateUrl: './activity-layout.component.html',
  styleUrls: ['./activity-layout.component.scss'],
})
export class ActivityLayoutComponent implements OnInit {
  // todo: level should come from back end as part of the activity property
  protected keyboardInstructionsExpression = '';
  protected activityBackgroundImg = '';
  protected showActivityOverlay = false;
  protected appEvent$!: Observable<IAppEvent>;

  // todo: can be observable
  protected appSettings: IAppSettings;
  protected activityStarted = false;
  protected activityCompleted = false;
  protected isSoundPaused = false;
  protected activity$: Observable<IActivityRegistry>;
  protected activityParams: Params;
  protected activity: IActivityRegistry;
  protected playButtonImage = '';
  protected playButtonCursorImage = '';
  protected isDarkBackground = false;

  @ViewChild(NarratorComponent) protected _narratorComp: NarratorComponent;

  private _welcomeExpression = '';

  private _destroyRef = inject(DestroyRef);
  private _appStateService = inject(AppStateService);
  private _soundService = inject(SoundService);
  private _activitiesService = inject(ActivityRegistryService);
  private _appSettingsService = inject(AppSettingsService);
  private _loadingService = inject(LoadingService);
  private readonly _route = inject(ActivatedRoute);
  private _dialogConfirmationService = inject(ConfirmationService);

  private _minWidth = 1024; // min supported Width
  private _minHeight = 768; // min supported Height

  isSoundPlaying$ = this._soundService.soundIsPlaying$;

  ngOnInit() {
    this.loadActivity();
    this.initAppState();

    setTimeout(() => {
      this.prepareActivityStart();
    }, DELAY.S2);

    this.initKeyEvents();

    this.registerDeviceOrientationEvent();

    // based upon level, we show different color buttons
    this.playButtonImage = `/assets/play-button-${this._appStateService.level}.svg`;
    this.playButtonCursorImage = `/assets/cursor-click-black.svg`;

    const self = this;
    window.addEventListener('resize', function () {
      if (this.window.innerWidth < self._minWidth || this.window.innerHeight < self._minHeight) {
        self.handleTooSmall();
      } else {
        self._dialogConfirmationService.close();
      }
    });

    // Listen for the skip introduction event
    this._appStateService.appEvent$
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter(event => event.area === APP_EVENT_AREAS.SKIP_INTRODUCTION)
      )
      .subscribe(() => {
        // Check if the activity hasn't started or hasn't been completed
        if (!this.activityCompleted && !this.activityStarted && this.showActivityOverlay) this.completeActivityStart();
        
        if (!this.activityCompleted && !this.activityStarted && !this.showActivityOverlay){          
          this._soundService.stopExpression();
          this.completeActivityStart();  // Skip directly to the activity start
        }
    });
  }

  loadActivity() {
    this.activity$ = this._route.queryParams.pipe(
      take(1),
      switchMap((params: Params) => {
        this.activityParams = params;
        this._activitiesService.setActivityParams(params['l'], params['u'], params['w'], params['asi']);
        return this._appStateService.activityRegistry$.pipe(
          tap((activity: IActivityRegistry) => {
            // set the background image
            this.activityBackgroundImg = "url('./assets/" + activity.theme.background.image + "')";
            this.isDarkBackground = this.activityBackgroundImg.includes('dark');
            this.loadAppSettings();
            this._loadingService.showLoading();
            // send activity to state for global access
            this._appStateService.appEvent$.next({
              area: APP_EVENT_AREAS.SET_ACTIVITY_REGISTRY,
              activityRegistry: activity,
            });
          })
        );
      })
    );

    // get some data out of the current activity
    this.activity$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((activity: IActivityRegistry) => {
      // play theme background track at lower volume
      this.activity = activity;
      this._welcomeExpression = activity.welcomeExpression;
    });
  }

  initAppState() {
    this.appEvent$ = this._appStateService.appEvent$;
    this.appEvent$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((event) => {
      if (event.area === APP_EVENT_AREAS.ACTIVITY_COMPLETE) {
        this.activityCompleted = event.showActivityComplete || false;
      }
    });
  }

  initKeyEvents() {
    fromEvent(window, 'keyup')
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter((event: any) => event.code === 'Enter' || event.code === 'Space')
      )
      .subscribe(() => {
        if (this.showActivityOverlay) {
          this.startActivity();
        }
      });
  }

  loadAppSettings() {
    this._appSettingsService
      .getAppSettings()
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((appSettings: IAppSettings) => {
        this.appSettings = appSettings;
        this.keyboardInstructionsExpression = this.appSettings.expressions.keyboardExpression;
      });
  }

  startActivity() {
    this.showActivityOverlay = false;

    this._appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.WELCOME,
      welcomeExpression: this._welcomeExpression,
      callback: () => {
        this._appStateService.appEvent$.next({
          area: APP_EVENT_AREAS.START_GAME,
        });
        this._appStateService.appEvent$.next({
          area: APP_EVENT_AREAS.SHRINK_NARRATOR,
        });
        this._soundService.playLoopSound(`${this.activity.theme.background.sound}`);
        this.activityStarted = true;
      },
    });
  }

  onSpeakerClick() {
    this.isSoundPaused = !this.isSoundPaused;
    this._soundService.pauseExpression();
  }

  private prepareActivityStart() {
    this._loadingService.hideLoading();
    this.showActivityOverlay = true;
    this._appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.CENTER_NARRATOR,
    });
  }

  private registerDeviceOrientationEvent() {
    // listen for device orientation events (triggers once on page load)
    fromEvent(window, 'deviceorientation')
      .pipe(
        distinctUntilChanged(),
        startWith(null),
        tap(() => this.handleOrientation()),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe();
  }

  private handleOrientation() {
    const orientation = getDeviceOrientation();
    if (orientation === DeviceOrientation.Portrait) {
      this._dialogConfirmationService.confirm({
        message: 'Portrait mode is currently unsupported. Please rotate your device to landscape mode for the best experience.',
        header: 'Orientation Warning',
        rejectVisible: false,
        acceptLabel: 'Ok',
        accept: () => {
          // logic after dialog is accepted
        },
      });
    } else if (orientation === DeviceOrientation.Landscape) {
      this._dialogConfirmationService.close();
    }
  }

  private handleTooSmall() {
    this._dialogConfirmationService.confirm({
      header: 'Please adjust your browser width and height for the best experience.',
      rejectVisible: false,
      acceptLabel: 'Ok',
    });
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === KEY_EVENTS.SpaceBar || event.key === KEY_EVENTS.Enter) {
      if (!this.showActivityOverlay) return;
      this.startActivity();
    }
  }

  // @HostListener('window:keydown', ['$event'])
  // handleKeyDown(event: KeyboardEvent) {
  //   // play/pause audio
  //   if (event.key === 'p') {
  //     if (!this.isSoundPlaying$) return;
  //     this.onSpeakerClick();
  //   }
  // }

  // Helper method to skip intro and directly go to puzzle
  completeActivityStart() {
    this.activityStarted = true; 
    this.showActivityOverlay = false;

    this._appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.START_GAME, 
    });
    this._appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.SHRINK_NARRATOR, 
    });
    this._soundService.playLoopSound(`${this.activity.theme.background.sound}`);
  } 
}
