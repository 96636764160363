<ng-container>
  <p class="read-paragraph">
    {{ this.currentWorldOfWordsDataset?.sentence }}
  </p>
  <button
  *ngIf="isRecording && this.rightButtonImage.length > 0"
  class="mic continue-button image-button"
  (click)="doRecord()"
  (keyup.enter)="doRecord()"
  (keyup.space)="doRecord()"
  tabindex="0"
  aria-label="Record"
  pTooltip="Record"
  tooltipPosition="left"
>
  <img [src]="micButtonImage" height="71" width="106" alt="microphone icon" aria-hidden="true" />
</button>
  <img
    *ngIf="!isRecording && this.rightButtonImage.length > 0"
    id="continue-button"
    [ngSrc]="rightButtonImage"
    height="71"
    width="106"
    (click)="onNextPuzzle()"
    (keyup.enter)="onNextPuzzle()"
    (keyup.space)="onNextPuzzle()"
    tabindex="0"
    alt="continue button"
    class="continue-button"
    pTooltip="Continue"
  />
</ng-container>
