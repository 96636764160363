import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IActivityRouteParams, RoutingService } from '@app/shared/services/routing.service';

// todo: type all of this
export const ValidateParamsResolver: ResolveFn<boolean> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  router: Router = inject(Router),
  routingService: RoutingService = inject(RoutingService)
): Observable<boolean> => {
  // get full route path
  const activityId = route.url[0].path;
  return routingService.validateParams(formatParams(activityId, route.queryParams)).pipe(
    map((res: boolean) => {
      if (!res) {
        routingService.redirectToPageNotFound();
        sessionStorage.clear();
      }
      return res;
    })
  );
};

const formatParams = (id: string, params: any): IActivityRouteParams => {
  return {
    activityId: id,
    unit: params['u'],
    week: params['w'],
    assignmentId: params['ai'],
    level: params['l'],
    activitySessionId: params['asi'],
    previewToken: params['token'],
  };
};
