import { Component, DestroyRef, ElementRef, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppStateService } from '@app/shared/services/app-state.service';
import { Observable } from 'rxjs/internal/Observable';
import { IAppEvent } from '@app/shared/types/app-event-interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';
import { AnimationHelpers } from '@app/shared/helpers/animation';
import { SOUND_NAMES } from '@app/shared/constants/sound-enums';
import { AnimationBurst } from '@app/shared/services/animation-burst.service';
import { BURST_THEME, BURST_TYPE } from '@app/shared/types/animation-burst.interface';
import { ANIMATIONS } from '@app/shared/constants/animation-enums';
import { DELAY } from '@app/shared/constants/delay-enums';


@Component({
  selector: 'app-narrator',
  templateUrl: './narrator.component.html',
  styleUrls: ['./narrator.component.scss'],
  imports: [CommonModule],
  standalone: true,
})
export class NarratorComponent {
  protected narratorImagePath: string;
  protected objectImagePath: string = '';
  protected objectCircleImagePath: string = '';
  protected isSmall = false;
  protected isProfile = false;
  protected isFade = false;
  protected profileImage = '';
  protected appEvent$: Observable<IAppEvent>;
  private _stateService = inject(AppStateService);
  private _destroyRef = inject(DestroyRef);
  protected _burst = inject(AnimationBurst);
  private _narratorImages: string[] = [];

  @ViewChild('narratorHost') narratorHost: ElementRef;

  constructor() {
    this.appEvent$ = this._stateService.appEvent$;
  }

  ngOnInit() {


    this._stateService.currentActivity$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((activity) => {
      this.objectImagePath = `/assets/${activity.theme.narrator.object}`;
      this.objectCircleImagePath = `/assets/object-circle-level${this._stateService.level}.svg`;
      this.profileImage = `${activity.theme.narrator.profile}`;
      this._narratorImages = `${activity.theme.narrator.image}`.split(',');
      this.getNarratorImage();
    });

    this.appEvent$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((event) => {
      if (event.area === APP_EVENT_AREAS.PROFILE_NARRATOR) {
        this.fadeTransition(true, true);
      } else if (event.area === APP_EVENT_AREAS.BOUNCE_NARRATOR) {
        this.burstProfile(event.boolValue || false, event.callback);
      }
      else if (event.area === APP_EVENT_AREAS.SHRINK_NARRATOR) {
        this.fadeTransition(false, true);
      } else if (event.area === APP_EVENT_AREAS.CENTER_NARRATOR) {
        this.fadeTransition(false, false);
      }
    });
  }

  fadeTransition(isProfile: boolean, isSmall: boolean) {

    if ((isProfile === this.isProfile) && (isSmall === this.isSmall)) {
      return;
    }
    this.isFade = true;
    setTimeout(() => {
      this.isProfile = isProfile;
      this.isSmall = isSmall;
      this.getNarratorImage();
      AnimationHelpers.animate('narrator-host', ANIMATIONS.ZoomInDown);
    }, DELAY.SMEDIUM)

    setTimeout(() => {
      this.isFade = false;
    }, DELAY.SMEDIUM)
  }

  getNarratorImage() {
    // narrator image may contain comma seperated images used in different pages in activity.
    this.narratorImagePath = `/assets/${this._narratorImages[0]}`;

    if (this.isProfile) {
      this.narratorImagePath = `/assets/${this.profileImage}`;
      return;
    }

    if (this._narratorImages.length < 2) {
      // no 2nd image, always use first one
      return;
    }

    const idx = this.isSmall ? 1 : 0; /// if there is a second one, use that one if not small
    this.narratorImagePath = `/assets/${this._narratorImages[idx]}`;
  }


  burstProfile(expandBounce: boolean, callback?: () => void | undefined) {

    let soundEffect: SOUND_NAMES;
    switch (this._stateService.level) {
      case 1:
        soundEffect = SOUND_NAMES.FairyArcade;
        break;
      case 2:
        soundEffect = SOUND_NAMES.BubbleBurst;
        break;
      case 3:
        soundEffect = SOUND_NAMES.FairyMagicSparkle;
        break;
      default:
        soundEffect = SOUND_NAMES.Achievement;
        break;
    }

    const isLevel2 = (this._stateService.level === 2);
    this._burst.animate(
      {
        containerId: 'burst-area',
        count: 5,
        soundEffect: soundEffect
      },
      BURST_TYPE.Round,
      false,
      isLevel2 ? BURST_THEME.Bubbles : BURST_THEME.Unknown
    );

    const anim = new AnimationHelpers();

    anim.bounceObject(
      {
        srcId: 'object',
        expand: expandBounce,
        callback: () => {
          callback ? callback() : undefined;
        }
      }
    );


  }
}
