import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { LetterPanelComponent } from '@app/shared/components/letter-panel/letter-panel.component';
import { FooterComponent } from '@app/shared/components/footer/footer.component';
import { Component, DestroyRef, Input, ViewChild, inject } from '@angular/core';
import { IEchoFindWordsDataset } from './types/echo-find-words-dataset-interface';
import { SoundService } from '@app/shared/services/sound.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivityBaseComponent } from '../activity-base/activity-base.component';
import { DELAY } from '@app/shared/constants/delay-enums';
import { BWEPuzzleType0Component } from './puzzle-types/0/puzzle-type-0.component';
import { BWEPuzzleType1Component } from './puzzle-types/1/puzzle-type-1.component';
import { BWEPuzzleType2Component } from './puzzle-types/2/puzzle-type-2.component';
import { BWEPuzzleType3Component } from './puzzle-types/3/puzzle-type-3.component';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';
import { ProgressBarComponent } from "../../../shared/components/progress-bar/progress-bar.component";


enum PUZZLE_TYPE {
  Basic = 0,
  SpellingOption,
  Doubling,
  Suffix,
  Unknown,
}

@Component({
  selector: 'app-echo-find-words-activity',
  templateUrl: './echo-find-words-activity.component.html',
  styleUrls: ['./echo-find-words-activity.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FooterComponent,
    LetterPanelComponent,
    NgOptimizedImage,
    A11yModule,
    BWEPuzzleType0Component,
    BWEPuzzleType1Component,
    BWEPuzzleType2Component,
    BWEPuzzleType3Component,
    ProgressBarComponent
],
})
export class EchoFindWordsActivityComponent extends ActivityBaseComponent {
  @Input() subPartComplete: boolean;

  protected buildEchoDatasets: IEchoFindWordsDataset[];
  protected currentBuildEchoDataset: IEchoFindWordsDataset | undefined;

  protected totalPuzzles: number = 0;
  protected displayPB: boolean = false;

  protected puzzleTypeEnum = PUZZLE_TYPE;
  protected puzzleType: PUZZLE_TYPE;

  // PT 0
  @ViewChild(BWEPuzzleType0Component)
  private _puzzleType0: BWEPuzzleType0Component;
  // PT 1
  @ViewChild(BWEPuzzleType1Component)
  private _puzzleType1: BWEPuzzleType1Component;
  // PT 2
  @ViewChild(BWEPuzzleType2Component)
  private _puzzleType2: BWEPuzzleType2Component;
  // PT 3
  @ViewChild(BWEPuzzleType3Component)
  private _puzzleType3: BWEPuzzleType3Component;

  private _destroyRef = inject(DestroyRef);
  private _soundService = inject(SoundService);

  @ViewChild(ProgressBarComponent)
  private _progressBarComp!: ProgressBarComponent;

  constructor() {
    super();
  }

  processKeyEvent(event: KeyboardEvent) { }

  initActivity(callback?: () => void | undefined): void {
    this.loadDataset()
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((datasetSrc) => {
        this.buildEchoDatasets = datasetSrc as IEchoFindWordsDataset[];
        this.buildEchoDatasets.forEach(lp => {
          const word = lp.word.split("-")
          this.totalPuzzles += word.length;
        })
        this.currentBuildEchoDataset = this.buildEchoDatasets?.find((d) => !d.completed);
        this.markerCount = this.buildEchoDatasets.length;
        callback ? callback() : undefined;
        this.listenForJumpToPuzzle();
        this.listenForPuzzleCompletion(); 
      });

      this.listenForCompleteAllPuzzles();
  }

  initPuzzle() {
    if (!this.currentBuildEchoDataset) {
      this.showActivitySolved();
      return;
    }

    this.puzzleType = this.currentBuildEchoDataset.puzzleType || 0;
    this.buildExpressions(this.currentBuildEchoDataset);
    this.appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.PROFILE_NARRATOR,
    });
    this.displayPB = true;
    this._soundService.playExpression(this.getExpression(1));
    this.appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.PUZZLE_CLUE,
      puzzleClueExpression: this.getExpression(1) || '',
    });

    setTimeout(() => {
      switch (this.puzzleType) {
        case PUZZLE_TYPE.Basic:
          this._puzzleType0.initPuzzle();
          break;
        case PUZZLE_TYPE.SpellingOption:
          this._puzzleType1.initPuzzle();
          break;
        case PUZZLE_TYPE.Doubling:
          this._puzzleType2.initPuzzle();
          break;
        case PUZZLE_TYPE.Suffix:
          this._puzzleType3.initPuzzle();
          break;
        default:
          break;
      }
    }, DELAY.ZERO);
  }

  getNextDataset() {
    this.updateSession();

    if (this.currentBuildEchoDataset) {
      this.currentBuildEchoDataset.completed = true;
        this.currentBuildEchoDataset = this.buildEchoDatasets.find((be) => !be.completed);
        this.initPuzzle();
    }
  }

  showActivitySolved() {
    this._progressBarComp.progressBarComplete(() => {
      this.activitySolved = true;
      this.puzzleType = PUZZLE_TYPE.Unknown;
      this._progressBarComp.reset();

      this.playEndExpression();
      this.toggleActivityCompletePage(true);
    });
  }

  startActivity() {
    if (this.activitySolved) {
      this.activitySolved = false;
      this.toggleActivityCompletePage(false);
    }

    this.initActivity(() => {
      this.setPerfectScore(this.buildEchoDatasets.length || 0);
      this.soundService.playExpression(this.currentActivity.startExpression, () => {
        this.initPuzzle();
        this._progressBarComp.markedComplete(this.buildEchoDatasets.filter((d) => d.completed)?.length);
      });
    });
  }

  onPuzzleComplete() {
    this.getNextDataset();
  }

  incrementProgress(data){
    this._progressBarComp.proceed(()=>{},data.passed)
  }

  private listenForJumpToPuzzle() {
    this.appStateService.appEvent$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((event) => {
      if (event.area === APP_EVENT_AREAS.JUMP_TO_PUZZLE) {
        if (event.payload < this.buildEchoDatasets.length) {
          this.currentBuildEchoDataset = this.buildEchoDatasets[event.payload];
          this.initPuzzle();
        }
      }
    });
  }

  private listenForPuzzleCompletion() {
    this.appStateService.activityDataset$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(dataset => {
        if (dataset) {
          this.buildEchoDatasets = dataset as IEchoFindWordsDataset[];
        }
      });
  }

  private listenForCompleteAllPuzzles(): void {
    this.appStateService.appEvent$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((event) => {
      if (event.area === APP_EVENT_AREAS.COMPLETE_ALL_PUZZLES) {
        this.completeAllPuzzles();
        this.updateActivityAfterCompletion();
      }
    });
  }
  
  private updateActivityAfterCompletion(): void {
    // Check if all puzzles are completed and update the UI accordingly
    this.currentBuildEchoDataset = this.buildEchoDatasets?.find((d) => !d.completed);
    if (!this.currentBuildEchoDataset) {
      this.showActivitySolved(); // Show activity completed if all puzzles are done
    } else {
      this.initPuzzle(); // Reinitialize the current puzzle if there are any left
    }
  }
}
