<div id="app-host" class="app-host" pFocusTrap>
  <app-audio-sidebar></app-audio-sidebar>
  <app-puzzle-chooser></app-puzzle-chooser>
  <app-debug-console></app-debug-console>

  <app-loading-state></app-loading-state>

  <div *ngIf="showActivityOverlay" id="play-button-container" class="activity-overlay mask" (click)="startActivity()">
    <button id="play-button" class="image-button round" aria-label="Click to Play!" (click)="startActivity()"
      pTooltip="Click to start activity!" trackClick="FHPStartedActivity">
      <img [ngSrc]="playButtonImage" alt="play button" height="120" width="120" aria-hidden="true" />
      <img id="play-cursor" [ngSrc]="playButtonCursorImage" alt="clicking cursor" height="73" width="68"
        aria-hidden="true" />
    </button>
  </div>

  <div class="activity-container" id="activity-container" *ngIf="activity$ | async as activity"
    [ngStyle]="{ 'background-image': activityBackgroundImg }">
    <!-- activity information, logo, buttons -->
    <app-activity-header [activity]="activity" [params]="activityParams" [activityCompleted]="activityCompleted"
      [keyboardInstructionsExpression]="this.appSettings.expressions.keyboardExpression"
      [isDarkBackground]="isDarkBackground"
      [activityStarted]="activityStarted"
      ></app-activity-header>
    <app-narrator #narrator *ngIf="activity$ | async as activity"></app-narrator>

    <app-score-panel></app-score-panel>

    <!-- primary activity window -->
    <router-outlet></router-outlet>
    <app-help-panel *ngIf=" appSettings && !showActivityOverlay"></app-help-panel>

    <app-welcome></app-welcome>

    <app-activity-complete></app-activity-complete>
    <app-play-again></app-play-again>

    <ng-container *ngIf="!showActivityOverlay && activityStarted && !activityCompleted" aria-hidden="true">
      <!-- Disable user interaction on entire page with overlay below -->
      <ng-container *ngIf="appEvent$ | async as appEvent">
        <div class="overlay" *ngIf="appEvent.disableUserInteraction"></div>
        <button class="image-button round speaker" [class.paused]="this.isSoundPaused" (click)="onSpeakerClick()"
          [attr.aria-label]="(isSoundPlaying$ | async) ? 'Pause Audio' : 'Play Audio'"
          [pTooltip]="(isSoundPlaying$ | async) ? 'Pause Audio' : 'Play Audio'"
          [tooltipDisabled]="!this.isSoundPaused && (isSoundPlaying$ | async) === false"
          [disabled]="!this.isSoundPaused && (isSoundPlaying$ | async) === false"
          trackClick="FHPPauseAudio">
          <img [ngSrc]="'/assets/sound-button-' + this.appSettings.level + '.svg'" alt="speaker icon" height="58"
            width="58" aria-hidden="true" />
        </button>
      </ng-container>
    </ng-container>
  </div>
</div>
