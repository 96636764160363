<!-- PuzzleType 1 - Sentence -->
<ng-container>
  <div id="wow-sentence" class="wow-sentence">
    <div id="sentence-host" class="sentence-host">
      <div id="sentence" class="sentence">
        <span>{{ sentenceFragments[0] }}</span>
        <span
          [id]="wordDiv"
          [attr.data-automationId]="'target-word-automation'"
          class="word"
          [ngClass]="{ solved: isPuzzleSolved, staged: isStaged }"
          [attr.aria-hidden]="!isPuzzleSolved"
        >
          {{ isPuzzleSolved || isStaged ? wordDefaultText : wordPlaceHolder }}</span
        >
        <span>{{ sentenceFragments[1] }}</span>
      </div>
    </div>

    <ol id="choices" class="choices flex justify-content-center flex-wrap" *ngIf="!isPuzzleSolved">
      <li
        tabindex="0"
        [id]="choice.id"
        *ngFor="let choice of sentenceChoices"
        class="choice flex align-items-center justify-content-center border-round m-2 animateSpan"
        (click)="onChoiceClicked(choice)"
        (keyup.enter)="onChoiceClicked(choice)"
        (keyup.space)="onChoiceClicked(choice)"
        (keyup)="this.onArrowKey($event)"
        [ngClass]="{ active: choice.active, correct: choice.solved, error: choice.error }"
        (focusin)="onFocusChange(choice)"
      >
        {{ choice.display }}
        <div class="answer-status" [ngClass]="{ statuscorrect: choice.solved, statuserror: choice.error }"></div>
      </li>
    </ol>
  </div>
</ng-container>
