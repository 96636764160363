import { SOUND_NAMES } from '../constants/sound-enums';
import { Point } from '@app/shared/types/point';

export interface IBurstOptions {
  containerId?: string;
  callback?: () => void;
  count?: number;
  offsetId?: string;
  toTop?: boolean;
  doublePos?: number; // for double burst... use either 1 or 2
  spread?: number; // spread in x direction
  soundEffect?: SOUND_NAMES;
}

export enum BURST_TYPE {
  Default,
  Round,
}

export interface IMotionPath {
  path: string;
  endPoint: Point;
  points: Point[];
}

export interface IBurstThemes {
  id: BURST_THEME;
  level: number;
  scale: number;
  svgImages: string[];
}

export enum BURST_THEME {
  Unknown = 0,
  Level1 = 1,
  Level2 = 2,
  Level3 = 3,
  Bubbles = 100

}
