import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, ViewChild } from '@angular/core';
import { ActivityBaseComponent } from '../activity-base/activity-base.component';
import { DELAY } from '@app/shared/constants/delay-enums';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IWorldOfWordsDataset } from './types/world-of-words-dataset-interface';
import { SoundService } from '@app/shared/services/sound.service';
import { WOWPuzzleType0Component } from './puzzles/0/puzzle-type-0.component';
import { WOWPuzzleType1Component } from './puzzles/1/puzzle-type-1.component';
import { WOWPuzzleType2Component } from './puzzles/2/puzzle-type-2.component';
import { WOWPuzzleType3Component } from './puzzles/3/puzzle-type-3.component';
import { IPuzzleCompleteEvent } from '@app/shared/types/puzzle-complete-event-interface';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';
import { ProgressBarComponent } from '@app/shared/components/progress-bar/progress-bar.component';

// PuzzleTypes of this activity
enum PUZZLE_TYPE {
  Words = 0,
  Sentence,
  ReadParagraph,
  BuildParagraph,
  Unknown,
}

@Component({
  selector: 'app-world-of-words',
  templateUrl: './world-of-words-activity.component.html',
  styleUrls: ['./world-of-words-activity.component.scss'],
  imports: [
    CommonModule,
    WOWPuzzleType0Component,
    WOWPuzzleType1Component,
    WOWPuzzleType2Component,
    WOWPuzzleType3Component,
    ProgressBarComponent
  ],
  standalone: true,
  providers: [],
})
export class WorldOfWordsActivityComponent extends ActivityBaseComponent {
  protected worldOfWordsDatasets: IWorldOfWordsDataset[];
  protected currentWorldOfWordsDataset: IWorldOfWordsDataset | undefined;

  protected totalPuzzles: number = 0;
  protected displayPB: boolean = false;

  protected finalParagraphReview: string;

  protected puzzleTypeEnum = PUZZLE_TYPE;
  protected puzzleType: PUZZLE_TYPE;

  // Puzzle Type 0
  @ViewChild(WOWPuzzleType0Component)
  private _puzzleType0: WOWPuzzleType0Component;
  // Puzzle Type 1
  @ViewChild(WOWPuzzleType1Component)
  private _puzzleType1: WOWPuzzleType1Component;
  // Puzzle Type 2
  @ViewChild(WOWPuzzleType2Component)
  private _puzzleType2: WOWPuzzleType2Component;
  // Puzzle Type 3
  @ViewChild(WOWPuzzleType3Component)
  private _puzzleType3: WOWPuzzleType3Component;

  private _destroyRef = inject(DestroyRef);
  private _soundService = inject(SoundService);

  @ViewChild(ProgressBarComponent)
  private _progressBarComp!: ProgressBarComponent;

  constructor() {
    super();
  }
  // must have this implemented. TODO make abstract in base class.
  processKeyEvent(event: KeyboardEvent) { }

  // must have this implemented. TODO make abstract in base class.
  initActivity(callback?: () => void | undefined): void {
    this.loadDataset()
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((datasetSrc) => {
        this.worldOfWordsDatasets = datasetSrc as IWorldOfWordsDataset[];
        this.totalPuzzles = this.worldOfWordsDatasets.length;
        this.currentWorldOfWordsDataset = this.worldOfWordsDatasets?.find((d) => !d.completed);
        this.markerCount = this.worldOfWordsDatasets?.length;
        callback ? callback() : undefined;

        this.listenForJumpToPuzzle();
      });
    this.listenForCompleteAllPuzzles();
  }

  // must have this implemented. TODO make abstract in base class.
  initPuzzle() {
    if (!this.currentWorldOfWordsDataset) {
      this.showActivitySolved();
      return;
    }

    this.puzzleType = this.currentWorldOfWordsDataset?.puzzleType || 0;
    this.buildExpressions(this.currentWorldOfWordsDataset);

    this.appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.PROFILE_NARRATOR,
    });
    this.displayPB = true;
    this._soundService.playExpression(this.getExpression(1));

    setTimeout(() => {
      switch (this.puzzleType) {
        case PUZZLE_TYPE.Words:
          this._puzzleType0.initPuzzle();
          break;
        case PUZZLE_TYPE.Sentence:
          this._puzzleType1.initPuzzle();
          break;
        case PUZZLE_TYPE.ReadParagraph:
          this._puzzleType2.initPuzzle();
          break;
        case PUZZLE_TYPE.BuildParagraph:
          this._puzzleType3.initPuzzle();
          break;
        default:
          break;
      }
    }, DELAY.ZERO);
  }

  // must have this implemented. TODO make abstract in base class.
  getNextDataset(status) {
    this.updateSession(); // update session metrics progress

    if (this.currentWorldOfWordsDataset) {
      this.currentWorldOfWordsDataset.completed = true;
      this._progressBarComp.proceed(() => {
        this.currentWorldOfWordsDataset = this.worldOfWordsDatasets?.find((tw) => !tw.completed);
        this.initPuzzle();
      }, status);
    }
  }

  // must have this implemented. TODO make abstract in base class.
  showActivitySolved() {
    this._progressBarComp.progressBarComplete(() => {
      this.activitySolved = true;
      this.puzzleType = PUZZLE_TYPE.Unknown;
      this._progressBarComp.reset();

      this.playEndExpression(() => { });
      this.toggleActivityCompletePage(true);
    });
  }

  // must have this implemented. TODO make abstract in base class.
  startActivity() {
    if (this.activitySolved) {
      this.activitySolved = false;
      this.toggleActivityCompletePage(false);
    }

    this.initActivity(() => {
      this.setPerfectScore(this.worldOfWordsDatasets?.length || 0);

      this.soundService.playExpression(this.currentActivity?.startExpression, () => {
        this.initPuzzle();
        this._progressBarComp.markedComplete(this.worldOfWordsDatasets.filter((d) => d.completed)?.length);
      });
    });
  }

  // must have this implemented. TODO make abstract in base class.
  onPuzzleComplete(puzzleComplete: IPuzzleCompleteEvent) {
    if (puzzleComplete.data) {
      this.finalParagraphReview = puzzleComplete.data; // for puzzle type 3 needs this.
    }
    this.getNextDataset(puzzleComplete.status);
  }

  private listenForJumpToPuzzle() {
    this.appStateService.appEvent$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((event) => {
      if (event.area === APP_EVENT_AREAS.JUMP_TO_PUZZLE) {
        if (event.payload < this.worldOfWordsDatasets.length) {

          // Set the correct dataset
          this.currentWorldOfWordsDataset = this.worldOfWordsDatasets[event.payload];

          // Update the puzzle type and reinitialize the puzzle UI
          this.puzzleType = this.currentWorldOfWordsDataset?.puzzleType;
          this.buildExpressions(this.currentWorldOfWordsDataset);

          setTimeout(() => {
            switch (this.puzzleType) {
              case PUZZLE_TYPE.Words:
                this._puzzleType0.initPuzzle();
                break;
              case PUZZLE_TYPE.Sentence:
                this._puzzleType1.initPuzzle();
                break;
              case PUZZLE_TYPE.ReadParagraph:
                this._puzzleType2.initPuzzle();
                break;
              case PUZZLE_TYPE.BuildParagraph:
                this._puzzleType3.initPuzzle();
                break;
              default:
                break;
            }
          }, DELAY.ZERO);
        }
      }
    });
  }

  private listenForCompleteAllPuzzles(): void {
    this.appStateService.appEvent$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((event) => {
      if (event.area === APP_EVENT_AREAS.COMPLETE_ALL_PUZZLES) {
        this.completeAllPuzzles();
        this.updateActivityAfterCompletion();
      }
    });
  }

  private updateActivityAfterCompletion(): void {
    // Check if all puzzles are completed and update the UI accordingly
    this.currentWorldOfWordsDataset = this.worldOfWordsDatasets?.find((d) => !d.completed);
    if (!this.currentWorldOfWordsDataset) {
      this.showActivitySolved(); // Show activity completed if all puzzles are done
    } else {
      this.initPuzzle(); // Reinitialize the current puzzle if there are any left
    }
  }

}
