// pass fail for puzzles
export enum PUZZLE_STATUS {
  FAIL = 0,
  PASS,
  UNKNOWN,
}

// Context for activity mistakes

export enum MISTAKE_CONTEXT {
  Wrong_Word = 0,
}
