<p-sidebar
  #sidebarRef
  [(visible)]="sidebarVisible"
  position="right"
  [modal]="false"
  [baseZIndex]="9999"
  styleClass="p-sidebar-sm"
  [showCloseIcon]="false"
  [style]="{ backgroundColor: '#EBF2F8' }"
  *ngIf="appEvent$ | async as appEvent"
>
  <ng-template pTemplate="headless">
    <div class="flex flex-column sidebar-content button-container">
      <div class="buttons flex align-items-center justify-content-end">
        <p-button type="button" icon="pi pi-copy" [cdkCopyToClipboard]="copyData" styleClass="h-2rem w-2rem"></p-button>
        <p-button type="button" (click)="closeSideBar($event)" icon="pi pi-times" styleClass="h-2rem w-2rem"></p-button>
      </div>
      <h3 class="sidebar-header">Missing Audio</h3>
      <hr class="separator" />
      <ul class="expression-list">
        <li *ngFor="let msg of messages; let i = index" class="expression-item">
          <p>{{ msg.expression }}</p>
          <p class="file">{{ msg.filename }}</p>
          <button *ngIf="i === 0 && buttonVisible" (click)="onDialogHidden(msg.callback)" class="p-button">Confirm</button>
        </li>
      </ul>
    </div>
  </ng-template>
</p-sidebar>
