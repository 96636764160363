export enum SOUND_NAMES {
  Correct = 'correct',
  Incorrect = 'incorrect',
  NextCard = 'nextcard',
  FlipCard = 'flipcard',
  Achievement = 'achievement',
  Highlight = 'highlight',
  ProgressComplete = 'progresscomplete',
  ActivityComplete = 'activitycomplete',
  Click = 'click',
  FlipCoin = 'flipcoin',
  Confetti = 'confetti',
  Dance = 'dance',
  Bubbles = 'bubbles',
  FinalScore = 'finalScore',
  BubbleBurst = 'BubbleBurst',
  FairyArcade = 'FairyArcade',
  FairyMagicSparkle = 'FairyMagicSparkle',
  LightSpell = 'LightSpell',
  GameLevelCompleted = 'GameLevelCompleted'
}
