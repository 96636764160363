import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, isFormGroup, Validators } from '@angular/forms';
import { KEY_EVENTS } from '@app/shared/constants/key-event-enums';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  @Input() length: number;
  @Input() loading: boolean;
  @Output() inputSubmit = new EventEmitter<string>();
  @Output() triggerHelp = new EventEmitter<string>();

  protected inputForm: FormGroup;
  // ngFor loop requires an iterable
  protected iterable: any[];
  protected isInputFilled: boolean;

  constructor(private _fb: FormBuilder, private _cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.iterable = new Array(this.length);
    this.inputForm = this._fb.group({
      inputVal: this._fb.array([]),
    });
    for (let i = 0; i < this.length; i++) {
      this.inputArray.push(this._fb.control('', [Validators.required]));
    }
  }

  onPaste(event: ClipboardEvent, index: number): void {
    if (index !== 0) return;

    let pastedText = event.clipboardData?.getData('text');
    pastedText = pastedText?.replace(/[^a-zA-Z0-9]+/g, ''); // cleanup pasted text

    if (!pastedText || pastedText?.length < this.length) return;

    this.insertValue(pastedText?.split('').slice(0, this.length));
  }

  insertValue(value: string[]): void {
    while (this.inputArray.length) {
      this.inputArray.removeAt(0);
    }
    value.forEach((letter) => {
      this.inputArray.push(this._fb.control(letter));
    });
  }

  get inputArray(): FormArray {
    return this.inputForm.get('inputVal') as FormArray;
  }

  onInput(event: any, index: number): void {
    const inputValue = event.target.value;

    let sanitizedValue;
    // alphanum for passcode, alpha only for initials
    if (this.length === 2) {
      sanitizedValue = inputValue.replace(/[^a-zA-Z]/g, '');
    } else {
      sanitizedValue = inputValue.replace(/[^a-zA-Z0-9]/g, '');
    }

    event.target.value = sanitizedValue;

    if (sanitizedValue === '') {
      return;
    }

    if (index < this.length - 1) {
      const nextInput = document.getElementById(`loginInput${index + 1}`);
      if (nextInput) {
        nextInput.focus();
        const field = nextInput as HTMLInputElement;
        field.select();
      }
    }
    // }

    if (inputValue.length === 0 && index > 0) {
      const prevInput = document.getElementById(`loginInput${index - 1}`);
      if (prevInput) {
        prevInput.focus();
        const field = prevInput as HTMLInputElement;
        field.select();
      }
    }

    // if all inputs are filled, show ok button
    this.isInputFilled = this.inputArray.value.every((value: string) => value !== '');
  }

  // join array into string before emitting
  onSubmit(value: string[]) {
    if (!this.inputForm.valid) return;
    this.inputSubmit.emit(value.join(''));
  }

  showHelpDialog() {
    this.triggerHelp.emit();
  }

  selectContents(event: Event): void {
    const field = event.target as HTMLInputElement;
    field.select();
  }

  public clearInputs() {
    this.isInputFilled = false;
    this.inputForm.reset();
  }

  protected readonly isFormGroup = isFormGroup;

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === KEY_EVENTS.Enter && this.inputForm.valid) {
      this.onSubmit(this.inputArray.value);
    }
  }
}
