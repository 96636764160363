import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  inject,
  Renderer2,
} from '@angular/core';
import { ICard } from '@app/shared/components/card/types/card-interface';
import { SoundService } from '../../services/sound.service';
import { CardComponent } from '../card/card.component';
import { DELAY } from '@app/shared/constants/delay-enums';
import { LEFT_PARENTHESES, RIGHT_PARENTHESES } from '@app/shared/constants/activity-constants';
import { SOUND_NAMES } from '@app/shared/constants/sound-enums';
import { AppStateService } from '@app/shared/services/app-state.service';
import { TooltipModule } from 'primeng/tooltip';
import { AudioRecordingService } from '@app/shared/services/audio-recording.service';
import { ActivitySessionService } from '@app/shared/services/activity-session.service';
import { AppHelpers } from '@app/shared/helpers/app-helpers';
import { WordFindFunActivityComponent } from '@app/pages/activities';
import { AnimationHelpers } from '@app/shared/helpers/animation';
import { ANIMATIONS } from '@app/shared/constants/animation-enums';

export enum LEVEL {
  Level_1 = 1,
  Level_2,
  Level_3,
}

const NUM_STRING = {
  ONE: '1',
  TWO: '2',
  THREE: '3',
  FOUR: '4',
  MINUS_ONE: '-1',
};

@Component({
  selector: 'app-card-rotator-component',
  templateUrl: './card-rotator.component.html',
  styleUrls: ['./card-rotator.component.scss'],
  standalone: true,

  imports: [CardComponent, CommonModule, NgOptimizedImage, TooltipModule],
})
export class CardRotatorComponent implements OnInit, AfterViewInit {
  @Input() cards: ICard[];
  @Input() autoplay = true;
  @Input() parent;
  @Output() carouselInit = new EventEmitter<string>();
  @Output() completedCards = new EventEmitter<void>();
  @ViewChildren('cell') private _cells!: QueryList<HTMLElement>;

  protected totalCardsShown = 0;
  protected currentIndex;

  // new
  private _cellCount = 0;
  private _selectedIndex = 0;
  private _activeCardIndex = 0;

  private _appState = inject(AppStateService);
  private _audioRecordingService = inject(AudioRecordingService);
  private _activitySessionService = inject(ActivitySessionService);
  private _soundService = inject(SoundService);
  private _renderer = inject(Renderer2);
  private isClicked = false;
  protected isRecording: boolean = false;

  protected levelEnum = LEVEL;
  protected currentLevel: number | undefined = this._appState.level;

  protected rightButtonImage = '';
  protected leftButtonImage = '';
  protected micButtonImage = '/assets/icons/text-to-speech.svg';

  constructor() {
    this.isRecording = AppHelpers.isRecording();
  }

  ngOnInit() {
    // error when init
    if (this.cards.length < 1) {
      throw new Error('Card-Rotator can`t be empty.');
    }
    //set dummy
    this.setCards(this.cards);
    this.rightButtonImage = `/assets/arrow-right-dark-${this.currentLevel}.svg`;
    if (this.parent instanceof WordFindFunActivityComponent) {
      // black cards
      this.currentLevel = undefined;
    }
  }

  ngAfterViewInit() {
    // after dom loads init carousel based on number of cards
    if (this.cards.length === 1) {
      this.handleSingleCard();
    } else if (this.cards.length === 2) {
      this.handleTwoCards();
    } else if (this.cards.length === 3) {
      this.handleThreeCards();
    } else {
      this.initCarousel();
    }
  }

  // init carousel
  initCarousel() {
    this.totalCardsShown = 0;
    let hitMid = false;
    let zIndex = 0;
    this._cells.forEach((c: any, i) => {
      this._renderer.setAttribute(c.nativeElement, 'data-position', (i + 1).toString());
      if (i == 3) {
        hitMid = true;
      }
      if (!hitMid) {
        zIndex += 1;
      } else {
        zIndex -= 1;
      }
      this._renderer.setStyle(c.nativeElement, 'zIndex', zIndex.toString());
    });
    this.totalCardsShown++;
  }

  // init carousel in case of single card
  handleSingleCard() {
    this.totalCardsShown = 0;
    this._cells.forEach((c: any, i) => {
      if (i === 0) {
        this._renderer.setAttribute(c.nativeElement, 'data-position', NUM_STRING.THREE);
        this._renderer.setStyle(c.nativeElement, 'zIndex', NUM_STRING.THREE);
      }
    });
  }

  // init carousel in case of two cards
  handleTwoCards() {
    this.totalCardsShown = 0;
    this._cells.forEach((c: any, i) => {
      if (i === 0) {
        this._renderer.setAttribute(c.nativeElement, 'data-position', NUM_STRING.THREE);
        this._renderer.setStyle(c.nativeElement, 'zIndex', NUM_STRING.THREE);
        this._renderer.removeClass(c.nativeElement, 'hidden');
      } else {
        this._renderer.setAttribute(c.nativeElement, 'data-position', NUM_STRING.ONE);
        this._renderer.setStyle(c.nativeElement, 'zIndex', NUM_STRING.MINUS_ONE);
        this._renderer.addClass(c.nativeElement, 'hidden');
      }
    });

    this.totalCardsShown++;
  }

  // init carousel in case of three cards
  handleThreeCards() {
    this.totalCardsShown = 0;
    this._cells.forEach((c: any, i) => {
      if (i === 0) {
        this._renderer.setAttribute(c.nativeElement, 'data-position', NUM_STRING.TWO); // First card
        this._renderer.setStyle(c.nativeElement, 'zIndex', NUM_STRING.TWO);
      } else if (i === 1) {
        this._renderer.setAttribute(c.nativeElement, 'data-position', NUM_STRING.THREE); // Middle card
        this._renderer.setStyle(c.nativeElement, 'zIndex', NUM_STRING.THREE);
      } else if (i === 2) {
        this._renderer.setAttribute(c.nativeElement, 'data-position', NUM_STRING.FOUR); // Last card
        this._renderer.setStyle(c.nativeElement, 'zIndex', NUM_STRING.TWO);
      }
    });
    this.totalCardsShown++;
  }

  rotate(inc: number, event?: MouseEvent) {
    if (this.isClicked) {
      event?.preventDefault();
      event?.stopPropagation();
      return;
    }

    this.isClicked = true;
    this._selectedIndex += inc;
    this._activeCardIndex += inc > 0 ? 1 : -1;

    const centerCard: any = this._cells.find((c: any) => c.nativeElement.dataset['position'] == NUM_STRING.THREE);
    const activeCard = this.findActiveCard(centerCard);

    // Handle single card scenario
    if (this.cards.length === 1) {
      this.handleSingleCardScenario(this.cards[0]);
      return;
    }

    // Handle two card scenario
    if (this.cards.length === 2) {
      this.handleTwoCardScenario(centerCard, activeCard);
      return;
    }

    // Handle three card scenario
    if (this.cards.length === 3) {
      this.handleThreeCardScenario(centerCard, activeCard);
      return;
    }

    // Handle more than three cards scenario
    if (activeCard) {
      this.handleMultipleCardsScenario(inc, activeCard);
    }
  }

  private findActiveCard(centerCard: any): any {
    return this.cards.find((c: any, i: number) => {
      if (c.content == centerCard?.nativeElement?.innerText) {
        this.currentIndex = i;
        return c;
      }
    });
  }

  private handleSingleCardScenario(singleCard: any) {
    this.isClicked = false;
    if (AppHelpers.isRecording()) this.isRecording = true;
    this.totalCardsShown = this.cards.length;
    setTimeout(() => {
      this._soundService.playExpression(singleCard.content);
      this.completedCards.emit();
    }, DELAY.S2);
  }

  private handleTwoCardScenario(centerCard: any, activeCard: any) {
    if (activeCard) {
      this._soundService.playExpression(activeCard.content, () => {
        this._soundService.playSound(SOUND_NAMES.NextCard);
        this.isClicked = false;
        if (AppHelpers.isRecording()) this.isRecording = true;
        this.totalCardsShown++;
        this.checkForCompletion();
        if (this.totalCardsShown > this.cards.length) return;
        this.updateCardPositionsTwo();
      });
    }
  }

  private updateCardPositionsTwo() {
    this._cells.forEach((c: any) => {
      const start = c.nativeElement.dataset.position;
      const end = start === NUM_STRING.THREE ? NUM_STRING.ONE : NUM_STRING.THREE;
      this._renderer.setAttribute(c.nativeElement, 'data-position', end);
      if (end === NUM_STRING.THREE) {
        this._renderer.removeClass(c.nativeElement, 'hidden');
      } else {
        this._renderer.addClass(c.nativeElement, 'hidden');
      }
    });
  }

  private handleThreeCardScenario(centerCard: any, activeCard: any) {
    if (activeCard) {
      this._soundService.playExpression(activeCard.content, () => {
        this._soundService.playSound(SOUND_NAMES.NextCard);
        this.isClicked = false;
        if (AppHelpers.isRecording()) this.isRecording = true;
        this.totalCardsShown++;
        this.checkForCompletion();
        if (this.totalCardsShown > this.cards.length) return;
        this.updateCardPositionsThree();
      });
    }
  }

  private updateCardPositionsThree() {
    this._cells.forEach((c: any) => {
      const start = c.nativeElement.dataset.position;
      let end: string = '';
      if (start === NUM_STRING.TWO) {
        end = NUM_STRING.FOUR;
      } else if (start === NUM_STRING.THREE) {
        end = NUM_STRING.TWO;
      } else if (start === NUM_STRING.FOUR) {
        end = NUM_STRING.THREE;
      }
      this._renderer.setAttribute(c.nativeElement, 'data-position', end);
      const zIndexValue = `${this._cellCount - Math.abs(parseInt(end) - 3)}`;
      this._renderer.setStyle(c.nativeElement, 'zIndex', zIndexValue);
    });
  }

  private handleMultipleCardsScenario(inc: number, activeCard: any) {
    this._soundService.playExpression(activeCard.content, () => {
      this._soundService.playSound(SOUND_NAMES.NextCard);
      this.isClicked = false;
      if (AppHelpers.isRecording()) this.isRecording = true;
      this.totalCardsShown++;
      this.checkForCompletion();
      if (this.totalCardsShown > this.cards.length) return;
      this.updateCardPositionsMultiple(inc);
    });
  }

  private updateCardPositionsMultiple(inc: number) {
    this._cells.forEach((c: any) => {
      const start = c.nativeElement.dataset.position;
      let end = inc > 0 ? parseInt(start) - 1 : parseInt(start) + 1;
      if (end > this._cellCount) {
        end = 1;
        this._renderer.setStyle(c.nativeElement, 'zIndex', NUM_STRING.MINUS_ONE);
      } else if (end < 1) {
        end = this._cellCount;
        this._renderer.setStyle(c.nativeElement, 'zIndex', NUM_STRING.MINUS_ONE);
      } else {
        this._renderer.removeStyle(c.nativeElement, 'zIndex');
      }
      this._renderer.setAttribute(c.nativeElement, 'data-position', end.toString());
    });
  }

  private checkForCompletion() {
    if (this.totalCardsShown > this.cards.length) {
      setTimeout(() => {
        this.completedCards.emit();
      }, DELAY.S2);
    }
  }

  private playExpression(expression: string) {
    // play expression, if it has a suffix then say them seperately.
    if (expression.includes(LEFT_PARENTHESES) || expression.includes(RIGHT_PARENTHESES)) {
      const segments = expression.split(LEFT_PARENTHESES);
      if (segments.length === 2) {
        const expressionOne = segments[0] ? segments[0] : '';
        const expressionTwo = expression.clean(LEFT_PARENTHESES).clean(RIGHT_PARENTHESES);

        this._soundService.playExpression(expressionOne);
        setTimeout(() => {
          this._soundService.playExpression(expressionTwo);
        }, DELAY.SMALL);
      }
    } else {
      this._soundService.playExpression(expression);
    }
  }

  async doRecord() {
    await this.recordAudio(DELAY.S5, "Recording session started");
  }

  private async recordAudio(delay: number, expression: string): Promise<void> {
    if (!AppHelpers.isRecording()) return;

    const recorder = await this._audioRecordingService.recordAudio();
    recorder.start();

    setTimeout(async () => {
      const recording = await recorder.stop();
      await this._activitySessionService.processAudio(recording.audioBlob, expression);
      this.isRecording = false;
    }, delay);

  }

  public setCards(cards: ICard[]) {
    cards = cards.filter((card) => !card.complete);
    cards = cards.sort(() => Math.random() - 0.5);
    this.cards = cards.map((card) => {
      return {
        ...card,
        content: card.content.trim(),
      };
    });
    this._cellCount = this.cards.length;
    this._activeCardIndex = 0;
  }

  private initPulseAnimation() {
    AnimationHelpers.animate('carousel-next', ANIMATIONS.Pulse, DELAY.M30);
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (this.currentIndex === 0) return;

    if (event.key === 'ArrowRight') {
      this.rotate(1);
    }
  }
}
