<div class="dialog-mask">
  <div class="dialog text-center">
    <h2>Cannot Load Activity</h2>
    <p>Oops, we encountered an error. Please try again.</p>
    <div class="cta-sound-combo mt-4">
      <button class="btn-primary" (click)="goBack()">Go Back</button>
      <button class="t2s-btn bottom-right" (click)="playTextToSpeech()" [disabled]="isTextToSpeechPlaying$ | async">
        <img *ngIf="isTextToSpeechPlaying$ | async" src="/assets/text-to-speech-inactive.svg" alt="text-to-speech disabled button" />
        <img
          *ngIf="(isTextToSpeechPlaying$ | async) === false"
          src="/assets/text-to-speech-active.svg"
          alt="text-to-speech button"
          pTooltip="Read popup content"
        />
      </button>
    </div>
  </div>
</div>
