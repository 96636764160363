// Strings
interface String {
  clean(del: string): string;
  trimAll(): string;
}

String.prototype.clean = function (del: string): string {
  return this.replaceAll(del, '');
};

String.prototype.trimAll = function (): string {
  return this.replace(/\s+/g, '');
};
