import { Injectable, ErrorHandler, inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from '../services/toast.service';
import { LoginService } from '@app/pages/login/services/login.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private _toast = inject(ToastService);
  private _loginService = inject(LoginService);
  handleError(error: Error | HttpErrorResponse) {
    if (error instanceof HttpErrorResponse) {
      // handle HTTP errors
      if (error.status === 401) this._loginService.logout().subscribe();
      // this._toast.setErrorToast('HTTP', error.message, 'warn');
    } else {
      // handle runtime errors
      //console.error(`Caught Runtime Error: ${error.message}`, error);
    }
  }
}
