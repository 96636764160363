<div
  [id]="letterPanel.id"
  [ngClass]="{
    active: letterPanel.active,
    error: letterPanel.error,
    success: letterPanel.success,
    whitebox: letterPanel.isWhiteBox,
    suffix: letterPanel.suffix,
    glue: letterPanel.gluedSound,
    vowel: letterPanel.vowel,
    letterPanel: letterPanel && !text,
    text: text
  }"
  (click)="handleClick()"
>
  <span class="content" *ngIf="!letterPanel.hidden || letterPanel.complete">
    {{ letterPanel.content }}
  </span>
  <div
    *ngIf="letterPanel.hidden || letterPanel.isWhiteBox"
    class="hidden-content-overlay"
    [ngClass]="{ whitebox: letterPanel.isWhiteBox }"
  ></div>
</div>
