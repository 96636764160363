import { Observable, of } from 'rxjs';

export enum DeviceOrientation {
  Portrait = 'portrait',
  Landscape = 'landscape',
  Unknown = 'unknown',
}

export const getDeviceOrientation = (): DeviceOrientation => {
  let orientation = DeviceOrientation.Unknown;
  // check for browser support
  if (typeof window !== 'undefined' && window.matchMedia) {
    if (window.matchMedia('(orientation: portrait)').matches) {
      orientation = DeviceOrientation.Portrait;
    } else if (window.matchMedia('(orientation: landscape)').matches) {
      orientation = DeviceOrientation.Landscape;
    }
  }

  // return unknown if browser doesn't support this detection method
  return orientation;
};
