export enum DELAY {
  ZERO = 0,
  SMALL = 250,
  SMEDIUM = 500,
  S1 = 1000,
  S2 = 2000,
  S3 = 3000,
  S4 = 4000,
  S5 = 5000,
  S6 = 6000,
  S7 = 7000,
  S8 = 8000,
  S9 = 9000,
  S10 = 10000,
  S11 = 11000,
  S12 = 12000,
  M1 = 60000,
  M30 = 1800000, // 30 min
}
