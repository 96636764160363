<app-card-rotator-component
  *ngIf="wordCards?.length && !carouselProceed"
  #carousel
  [parent]="this"
  [cards]="wordCards"
  (completedCards)="onCarouselCompleted()"
  class="word-talk-carousel"
></app-card-rotator-component>

<div id="burst-area"></div>
<div class="word-area" id="word-area" *ngIf="carouselProceed">
  <div
    id="answers"
    class="answers flex justify-content-center flex-wrap gap-3"
    *ngIf="!activitySolved && carouselProceed"
    [ngClass]="{ oneRow: correctCards.length < 5 || correctCards.length > 6 }"
  >
    <div
      class="answer flex align-items-center justify-content-center border-round m-2 animateSpan"
      [id]="'answer-' + answer.id"
      *ngFor="let answer of correctCards"
      [ngClass]="{ correct: answer.complete, complete: answer.complete }"
    >
      <span *ngIf="answer.complete">
        {{ answer.content }}
        <div class="answer-status" [ngClass]="{ statuscorrect: answer.complete }"></div>
      </span>
    </div>
  </div>

  <div
    id="choices"
    class="choices flex justify-content-center flex-wrap gap-1"
    *ngIf="!activitySolved && carouselProceed"
    aria-hidden="true"
  >
    <div
      tabindex="0"
      [id]="choice.id"
      class="choice flex align-items-center justify-content-center border-round m-2 animateSpan"
      #choice
      *ngFor="let choice of wordCards"
      (click)="onCardClicked(choice)"
      (keyup.enter)="onCardClicked(choice)"
      (keyup.space)="onCardClicked(choice)"
      (keyup)="onArrowKey($event)"
      [attr.data-validAnswer]="correctWords.includes(choice)"
      [ngClass]="{ active: choice.active === true, correct: choice.complete, error: choice.error, semiTrans: choice.semiTrans }"
    >
      {{ choice.content }}
      <div class="answer-status" [ngClass]="{ statuscorrect: choice.complete, statuserror: choice.error }"></div>
    </div>
  </div>
</div>

<ng-container *ngIf="carouselCompleted && !carouselProceed">
  <app-start-button (playButtonClicked)="onCarouselProceedClick()"> </app-start-button>
</ng-container>
