<div #narratorHost class="narrator-host" id="narrator-host"
  [ngClass]=" { small: isSmall, profile: isProfile, fade: isFade }">
  <div class="narrator-burst" id="narrator-burst-1"></div>
  <div class="narrator-burst" id="narrator-burst-2"></div>
  <img [src]="narratorImagePath" id="narrator" class="narrator" alt="narrator" />
  <ng-container *ngIf="isProfile">
    <div id="burst-area"></div>
    <img [src]="objectCircleImagePath" id="object-circle" class="object-circle" alt="object-circle" />
    <img [src]="objectImagePath" id="object" class="object" alt="object" />
  </ng-container>


</div>