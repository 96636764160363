<ng-container *ngIf="appEvent$ | async as appEvent">
  <div
    id="overlay"
    class="overlay high"
    *ngIf="showPage"
    aria-hidden="true"
    (click)="toggleVisibility()"
    [ngClass]="{
      high: this.range === this.rangeEnum.HIGH,
      medium: this.range === this.rangeEnum.MEDIUM,
      low: this.range === this.rangeEnum.LOW
    }"
  >
    <div class="header">
      <div class="title">Scoring</div>

      <div class="puzzle-count">Puzzles: {{ this.scoringService.puzzlesTaken }} / {{ this.scoringService.perfectScore }}</div>
      <div class="tries">Tries: {{ this.scoringService.numTries }} / {{ this.scoringService.maxTries }}</div>
      <div class="self-corrections">Corrections: {{ this.scoringService.selfCorrections }}</div>
    </div>

    <div class="score-host">
      <div class="score">{{ this.scoringService.currentScore }} <span>%</span></div>
      <div class="out-of">{{ this.scoringService.puzzlesPassed }} / {{ this.scoringService.puzzlesTaken }}</div>
    </div>
  </div>
</ng-container>
