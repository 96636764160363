import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';
import { DELAY } from '@app/shared/constants/delay-enums';
import { SOUND_NAMES } from '@app/shared/constants/sound-enums';
import { AppStateService } from '@app/shared/services/app-state.service';
import { SoundService } from '@app/shared/services/sound.service';
import { ProgressBarModule } from 'primeng/progressbar'

export enum LEVEL {
  Level_1 = 1,
  Level_2,
  Level_3,
}

@Component({
  selector: 'app-progress-bar',
  standalone: true,
  imports: [CommonModule, ProgressBarModule],
  templateUrl: './progress-bar.component.html',
  styleUrl: './progress-bar.component.scss'
})
export class ProgressBarComponent {
  @Input() totalPuzzles: number;

  protected answeredPuzzles = 0;

  protected progressValue: number = 0;

  private _appStateService = inject(AppStateService);

  protected currentLevel: number | undefined = this._appStateService.level;


  constructor(private _soundService: SoundService) { }


  proceed(cb?: () => void | undefined, bounce = false) {
    if (bounce) {
      this._appStateService.appEvent$.next({
        area: APP_EVENT_AREAS.BOUNCE_NARRATOR,
        boolValue: true,
        callback: () =>  {
          this.increment();
          cb ? cb() : undefined;
        }
      });
    }else{
      this.increment();
        cb ? cb() : undefined;
    }
    
  }

  increment() {
    this.answeredPuzzles++;

    if (this.answeredPuzzles < this.totalPuzzles) {
      this.progressValue = (this.answeredPuzzles / this.totalPuzzles) * 100;
    } else {
      this.progressValue = (this.answeredPuzzles / this.totalPuzzles) * 100;
    }
  }

  progressBarComplete(callback?: () => void | undefined) {
    this._soundService.playSound(SOUND_NAMES.ProgressComplete, () => {
      callback ? callback() : undefined;
    });
  }

  markedComplete(completedPuzzles: number) {
    this.progressValue = (completedPuzzles / this.totalPuzzles) * 100;
  }

  reset() {
    this.progressValue = 0;
  }
}
