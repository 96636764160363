import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit, ViewChild, inject, DestroyRef } from '@angular/core';
import { Sidebar, SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { Observable } from 'rxjs';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IAppEvent } from '@app/shared/types/app-event-interface';
import { AppStateService } from '@app/shared/services/app-state.service';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';
import { IActivityRegistry } from '@app/shared/types/activity-registry.interface';
import { ACTIVITY_IDS } from '@app/shared/constants/activity-ids';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { environment } from '@env/environment';
import { FormsModule } from '@angular/forms';
import { SoundService } from '@app/shared/services/sound.service';


@Component({
  selector: 'app-puzzle-chooser',
  standalone: true,
  imports: [CommonModule, SidebarModule, ButtonModule, ClipboardModule, NgxJsonViewerModule, FormsModule],
  templateUrl: './puzzle-chooser.component.html',
  styleUrls: ['./puzzle-chooser.component.scss'],
})
export class PuzzleChooserComponent implements OnInit {
  @ViewChild('sidebarRef') sidebarRef!: Sidebar;

  protected appEvent$: Observable<IAppEvent>;
  protected callback: () => void;
  protected sidebarVisible = false;

  protected ACTIVITY_IDS = ACTIVITY_IDS;
  protected currentActivity: IActivityRegistry;
  protected currentDataset: any; // type this ?
  protected states: boolean[] = [];

  private _appStateService = inject(AppStateService);
  private _soundService = inject(SoundService);
  private _destroyRef = inject(DestroyRef);
  private readonly PUZZLE_TYPE_MARKING = 0;

  constructor() { }

  ngOnInit(): void {
    this.appEvent$ = this._appStateService.appEvent$;

    this._appStateService.currentActivity$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((activity) => {
      this.currentActivity = activity;
    });


    this._appStateService.activityDataset$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((dataset) => {
      if (this.currentActivity?.id === ACTIVITY_IDS.WordFindFun) {
        this.currentDataset = dataset.filter(d => d.puzzleType !== this.PUZZLE_TYPE_MARKING);
      } else {
        this.currentDataset = dataset;
      }
      this.states = dataset.map(d => d.completed);
    });
  }

  onPuzzleClick(event: Event, puzzleId: number) {
    if ((event.target as HTMLElement).tagName === 'INPUT') {
      return;
    }

    this.states.forEach((s, index) => {
      this.states[index] = false;
      return false;
    });

    this.states[puzzleId] = true;

    this._soundService.stopExpression();

    this._appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.JUMP_TO_PUZZLE,
      payload: puzzleId
    });
  }

  closeSideBar(e): void {
    this.sidebarRef.close(e);
  }

  @HostListener('document:keydown', ['$event'])
  clickKeyDown(event: KeyboardEvent) {
    if (event.ctrlKey && event.shiftKey && event.key === '~') {
      if (!environment.production) {
        this.sidebarVisible = !this.sidebarVisible;
      }

    }
  }

  omitExpressions(row: any): any {
    const { expressions, ...rest } = row;
    return rest;
  }

  onPuzzleToggle(event: Event, index: number): void {
    event.stopPropagation();
    this._soundService.stopExpression();

    // Toggle the completion status manually
    const puzzle = this.currentDataset[index];
    const isChecked = (event.target as HTMLInputElement).checked;
    puzzle.completed = isChecked;

    // Update the puzzle completion status via the AppStateService
    this._appStateService.updatePuzzleCompletion(index, puzzle.completed);

    // Check if all puzzles are completed
    const allCompleted = this.currentDataset.every(p => p.completed);

    if (allCompleted) {
        // Trigger the activity completion screen
        this._appStateService.appEvent$.next({
            area: APP_EVENT_AREAS.COMPLETE_ALL_PUZZLES,
        });
    } else if (puzzle.completed) {
        // Optionally, you can auto-navigate to the next incomplete puzzle
        const nextIncompleteIndex = this.currentDataset.findIndex(p => !p.completed);
        if (nextIncompleteIndex !== -1) {
            this._appStateService.appEvent$.next({
                area: APP_EVENT_AREAS.JUMP_TO_PUZZLE,
                payload: nextIncompleteIndex
            });
        }
    }
  }

  completeAllPuzzles(): void {
    // Emit event to complete all puzzles
    this._soundService.stopExpression();

    this._appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.COMPLETE_ALL_PUZZLES,
      payload: true
    });
  }

  onSidebarShow(): void {
    this.sidebarVisible = true;

    this._appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.SKIP_INTRODUCTION
    })
  }
}
