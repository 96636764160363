<div id="pt2">
  <ng-container class="active-word-area">
    <div class="active-word">
      <div *ngFor="let letter of letterPanelsActiveWord" class="active-letter">
        <app-letter-panel-component [letterPanel]="letter" 
        [text]="true"></app-letter-panel-component>
      </div>
    </div>
  </ng-container>
  <app-choice-bank
    [choices]="this.letterPanelsChoices"
    [validatingPuzzle]="validatingPuzzle"
    *ngIf="this.letterPanelsChoices.length > 0"
    (chosenPanel)="onLetterChoiceSelected($event)"
  >
  </app-choice-bank>
</div>
