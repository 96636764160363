export const LETTER_SEPARATOR = '-';
export const SYLLABLE_SEPARATOR = '|';
export const LEFT_PARENTHESES = '(';
export const RIGHT_PARENTHESES = ')';
export const LEFT_BRACE = '{';
export const RIGHT_BRACE = '}';
export const LEFT_BRACKET = '[';
export const RIGHT_BRACKET = ']';
export const COMMA_SEPARATOR = ',';
export const COLON_SEPARATOR = ':';
export const PERIOD = '.';
export const QUESTION_MARK = '?';
export const EXCLAMATION_MARK = '!';
export const SCOOP_SEPARATOR = '~';
export const AT_SYMBOL = '@';
export const DOUBLE_UNDERSCORE = '__';
export const CORRECT = 'correct';
export const INCORRECT = 'incorrect';
export const APOSTROPHE = "'";
