import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { IEchoFindWordsDataset } from '../../types/echo-find-words-dataset-interface';
import { IPuzzleCompleteEvent } from '@app/shared/types/puzzle-complete-event-interface';
import { PuzzleTypeBaseComponent } from '@app/pages/activities/puzzle-type-base/puzzle-type-base.component';
import { LetterPanelComponent } from '@app/shared/components/letter-panel/letter-panel.component';
import { ChoiceBankComponent } from '@app/shared/components/choice-bank/choice-bank.component';
import { AppHelpers } from '@app/shared/helpers/app-helpers';
import { ILetterPanel } from '@app/shared/components/letter-panel/types/letter-panel-interface';
import { CommonModule } from '@angular/common';
import { SOUND_NAMES } from '@app/shared/constants/sound-enums';
import { MISTAKE_CONTEXT, PUZZLE_STATUS } from '@app/shared/constants/session-enums';
import { AnimationHelpers } from '@app/shared/helpers/animation';
import { ANIMATIONS } from '@app/shared/constants/animation-enums';
import { BURST_TYPE } from '@app/shared/types/animation-burst.interface';
import { DELAY } from '@app/shared/constants/delay-enums';
import { AppStateService } from '@app/shared/services/app-state.service';

enum PUZZLE_LEVEL {
  Zippy = 1,
  Ollie,
  Domino,
}

@Component({
  selector: 'app-bwe-puzzle-type-0',
  standalone: true,
  imports: [LetterPanelComponent, ChoiceBankComponent, CommonModule],
  templateUrl: './puzzle-type-0.component.html',
  styleUrl: './puzzle-type-0.component.scss',
})
export class BWEPuzzleType0Component extends PuzzleTypeBaseComponent {
  @Input() currentBuildEchoDataset: IEchoFindWordsDataset | undefined;
  @Input() expressions: Map<number, string>;
  @Output() puzzleComplete = new EventEmitter<IPuzzleCompleteEvent>();
  @Output() subPartComplete = new EventEmitter();

  private _appState = inject(AppStateService);

  protected letterPanelsActiveWord: ILetterPanel[] = [];
  protected letterPanelsChoices: ILetterPanel[] = [];
  protected puzzleLevelEnum = PUZZLE_LEVEL;
  protected currentLevel: number | undefined = this._appState.level;


  private targetPanel: ILetterPanel = this.letterPanelsActiveWord.filter((lp) => !lp.complete)[0];
  

  constructor() {
    super();
    this.emitCall = this.puzzleComplete;
  }

  initPuzzle() {
    this.setExpressions(this.expressions);
    this.startPuzzleMetrics();
    this.initActiveWord();
    this.initChoicePanels();
    this.setMaxTries(this.currentBuildEchoDataset?.maxTries)
    this.targetPanel = this.letterPanelsActiveWord.filter((lp) => !lp.complete)[0];
    this.validatingPuzzle = false;
  }

  initActiveWord() {
    if (this.currentBuildEchoDataset) {
      AppHelpers.buildTargetWordPanels(this.currentBuildEchoDataset.word, this.letterPanelsActiveWord);
    }
  }

  initChoicePanels() {
    if (this.currentBuildEchoDataset) {
      AppHelpers.buildChoicePanels(this.currentBuildEchoDataset.choices, this.letterPanelsChoices);
    }
  }

  onLetterChoiceSelected(selectedPanel: ILetterPanel) {
    if (this.validatingPuzzle) {
      return;
    }

    this.validatingPuzzle = true;

    if (!selectedPanel.complete) {
      selectedPanel.active = true;

      if (selectedPanel.isCorrectAnswer && selectedPanel.content === this.targetPanel.content) {
        selectedPanel.success = true;

        this.animatePanel(selectedPanel, () => {
          this.soundService.playSound(SOUND_NAMES.Correct, () => {
            selectedPanel.success = false;
            selectedPanel.active = false;
          });

          if (!this.getNextTarget()) {
            this.soundService.playExpression(this.getExpression(2), () => {
              const burstBehind = this.letterPanelsActiveWord.find((lp, i) => i === Math.floor(this.letterPanelsActiveWord.length / 2));
              this.burst.animate({ soundEffect: SOUND_NAMES.Achievement, offsetId: burstBehind?.id }, BURST_TYPE.Round);
              setTimeout(() => {
                this.subPartComplete.emit({newPuzzle: true, passed: !this._selfCorrected});
                setTimeout(() =>{
                  this.completePuzzle(this._selfCorrected ? PUZZLE_STATUS.FAIL : PUZZLE_STATUS.PASS);
                }, DELAY.S2)
              }, DELAY.S2);
            });
          } else {
            this.subPartComplete.emit(false);
            this.validatingPuzzle = false;
          }
        });
      } else {
        selectedPanel.error = true;

        this.addUserMistakeDetails({
          content: selectedPanel.content,
          context: MISTAKE_CONTEXT.Wrong_Word,
        });

        AnimationHelpers.animate(selectedPanel.id, ANIMATIONS.ShakeX);

        if (this.hasTriesExceeded()) {
          this.soundService.playSound(SOUND_NAMES.Incorrect);
          this.addMistake();

          if (!this._selfCorrected) {
            this.subpartSelfCorrected(true);
          }

          const correctPanel = this.letterPanelsChoices.find((lp) => lp.isCorrectAnswer && lp.content === this.targetPanel?.content);

          this.soundService.playExpression(this.getExpression(4), () => {
            selectedPanel.error = false;
            selectedPanel.active = false;

            if (correctPanel) {
              this.animatePanel(correctPanel, () => {
                if (!this.getNextTarget()) {
                  this.validatingPuzzle = true;
                  setTimeout(() => {
                    this.subPartComplete.emit({newPuzzle: true, passed: !this._selfCorrected});
                    setTimeout(() =>{
                      this.completePuzzle(this._selfCorrected ? PUZZLE_STATUS.FAIL : PUZZLE_STATUS.PASS);
                    }, DELAY.S2)
                  }, DELAY.S2);
                } else {
                  this.subPartComplete.emit(false);
                  this.validatingPuzzle = false;
                }
              });
            }
          });
        } else {
          this.soundService.playSound(SOUND_NAMES.Incorrect, () => {
            this.soundService.playExpression(this.getExpression(3), () => {
              selectedPanel.error = false;
              selectedPanel.active = false;
              this.validatingPuzzle = false;
            });
          });
        }
      }
    }
  }

  // animate panel with callback to corresponding spot
  private animatePanel(panel: ILetterPanel, callback?: () => void | undefined) {
    if (!panel) {
      callback ? callback() : undefined;
      return;
    }

    this.anim.animateTo(panel.id, this.targetPanel?.id, () => {
      if (this.targetPanel) this.targetPanel.complete = true;
      callback ? callback() : undefined;
    });
  }

  // grab next target clicking correct/ auto correct latter of current word
  getNextTarget() {
    this.targetPanel = this.letterPanelsActiveWord.filter((lp) => !lp.complete)[0];
    if (!this.targetPanel) {
      this.resetTries();
      return false;
    }
    this.resetTries();
    return true;
  }
}
