import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface IAudioError {
  expression: string;
  filename: string;
  callback: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class ErrorDialogService {
  private _errorSubject = new Subject<IAudioError>();
  error: IAudioError | null;
  visible = false;

  setErrorDialog(file, expression, callback) {
    this.error = {
      expression: expression,
      filename: file,
      callback: callback,
    };
    this.visible = true;
    this._errorSubject.next(this.error);
  }
  accessVisibilty() {
    return this.visible;
  }

  getErrorData() {
    return this._errorSubject.asObservable();
  }

  clearError() {
    this.error = null;
  }
}
