import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PuzzleTypeBaseComponent } from '@app/pages/activities/puzzle-type-base/puzzle-type-base.component';
import { ILetterPanel } from '@app/shared/components/letter-panel/types/letter-panel-interface';
import { ANIMATIONS } from '@app/shared/constants/animation-enums';
import { SOUND_NAMES } from '@app/shared/constants/sound-enums';
import { AnimationHelpers } from '@app/shared/helpers/animation';
import { AppHelpers } from '@app/shared/helpers/app-helpers';
import { IPuzzleCompleteEvent } from '@app/shared/types/puzzle-complete-event-interface';
import { IMakeAChangeDataset } from '../../types/make-a-change-dataset-interface';
import { LetterPanelComponent } from '@app/shared/components/letter-panel/letter-panel.component';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChoiceBankComponent } from '@app/shared/components/choice-bank/choice-bank.component';
import { BURST_TYPE } from '@app/shared/types/animation-burst.interface';
import { DELAY } from '@app/shared/constants/delay-enums';
import { MISTAKE_CONTEXT, PUZZLE_STATUS } from '@app/shared/constants/session-enums';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';

@Component({
  selector: 'app-mac-puzzle-type-2',
  standalone: true,
  imports: [LetterPanelComponent, CommonModule, ChoiceBankComponent, NgOptimizedImage],
  templateUrl: './puzzle-type-2.component.html',
  styleUrl: './puzzle-type-2.component.scss',
})
export class MACPuzzleType2Component extends PuzzleTypeBaseComponent {
  @Input() currentMacDataset: IMakeAChangeDataset | undefined;
  @Input() expressions: Map<number, string>;
  @Input() newGroup: boolean;
  @Output() puzzleComplete = new EventEmitter<IPuzzleCompleteEvent>();
  @Output() subPartComplete = new EventEmitter();


  protected showChain: boolean = false;
  protected formatChain: any[] = [];
  protected resultLetters: any[] = [];
  protected currentLevel = this.appStateService.level;
  protected rightButtonImage = `/assets/arrow-right-dark-${this.currentLevel}.svg`;

  protected letterPanelsActiveWord: ILetterPanel[] = [];
  protected letterPanelsChoices: ILetterPanel[] = [];

  private targetPanel: ILetterPanel;

  constructor() {
    super();
    this.emitCall = this.puzzleComplete;
  }

  initPuzzle() {
    this.initActiveWord();
    this.initChoicePanels();

    this.appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.PUZZLE_CLUE,
      puzzleClueExpression: '',
    })

    this.resultLetters.push(...this.letterPanelsActiveWord);
    this.resultLetters.forEach((lp) => {
      lp.success = false;
      lp.active = false;
    });
    this.formatChain = this.splitChainResult(this.resultLetters);
    
    if (!this.currentMacDataset?.expressions) {
      this.showChain = true;
      return;
    } else {
      this.showChain = false;
    }
    if (this.newGroup) {
      this.startPuzzleMetrics();
    }
    this.resetTries();
    this.setExpressions(this.expressions);
    this.setMaxTries(this.currentMacDataset?.maxTries)
    this.targetPanel = this.letterPanelsActiveWord.filter((lp) => lp.isCorrectAnswer)[0];
    this.validatingPuzzle = false;
    this.soundService.playExpression(this.getExpression(1), () => {
      AnimationHelpers.animate('active-word', ANIMATIONS.Pulse);
      if (this.getExpression(2)) {
        this.soundService.playExpression(this.getExpression(2), () => {
          AnimationHelpers.animate('choice-bank', ANIMATIONS.Pulse);
        });
      }
    });

    if(!this.showChain) {
      this.appStateService.appEvent$.next({
        area: APP_EVENT_AREAS.PUZZLE_CLUE,
        puzzleClueExpression: this.getExpression(1) || '',
      });
    }
  }


  initActiveWord() {
    if (this.currentMacDataset) {
      AppHelpers.buildTargetWordPanels(this.currentMacDataset.word, this.letterPanelsActiveWord);
    }
    this.letterPanelsActiveWord.forEach((lp) => {
      lp.hidden = false;
    });
    AnimationHelpers.animate('active-word', ANIMATIONS.Pulse);
  }

  initChoicePanels() {
    if (this.currentMacDataset) {
      AppHelpers.buildChoicePanels(this.currentMacDataset.choices, this.letterPanelsChoices);
    }
  }

  onLetterChoiceSelected(selectedPanel: ILetterPanel) {
    if (this.validatingPuzzle) {
      return;
    }

    this.soundService.playSound(SOUND_NAMES.Click);

    this.validatingPuzzle = true;
    selectedPanel.active = true;

    if (selectedPanel.isCorrectAnswer) {
      selectedPanel.success = true;

      // display mark

      if (this.getExpression(1)?.includes('add') || this.getExpression(1)?.includes('Add')) {
        this.soundService.playSound(SOUND_NAMES.Correct, () => {
          selectedPanel.success = false;
          selectedPanel.active = false;
          this.soundService.playExpression(this.getExpression(3), () => {
            const burstBehind = this.letterPanelsActiveWord.find((lp, i) => i === Math.floor(this.letterPanelsActiveWord.length / 2));
            this.burst.animate({ offsetId: burstBehind?.id }, BURST_TYPE.Round);
            this.subPartComplete.emit(false);
            this.emitCall.emit();          });
        });
      } else {
        this.animatePanel(selectedPanel, () => {
          this.soundService.playSound(SOUND_NAMES.Correct, () => {
            selectedPanel.success = false;
            selectedPanel.active = false;
            this.soundService.playExpression(this.getExpression(3), () => {
              const burstBehind = this.letterPanelsActiveWord.find((lp, i) => i === Math.floor(this.letterPanelsActiveWord.length / 2));
              this.burst.animate({ offsetId: burstBehind?.id }, BURST_TYPE.Round);
              this.subPartComplete.emit(false);
            this.emitCall.emit();
            });
          });
        });
      }
      return;
    } else {
      selectedPanel.error = true;

      this.addUserMistakeDetails({
        content: selectedPanel.content,
        context: MISTAKE_CONTEXT.Wrong_Word,
      });

      AnimationHelpers.animate(selectedPanel.id, ANIMATIONS.ShakeX);

      if (this.hasTriesExceeded()) {
        this.soundService.playSound(SOUND_NAMES.Incorrect);
        this.addMistake();

        if (!this._selfCorrected) {
          this.subpartSelfCorrected(true);
        }

        const correctPanel = this.letterPanelsChoices.filter((lp) => lp.isCorrectAnswer)[0];

        this.soundService.playExpression(this.getExpression(5), () => {
          selectedPanel.error = false;
          selectedPanel.active = false;
          correctPanel.success = true;
          if (this.getExpression(1)?.includes('add') || this.getExpression(1)?.includes('Add')) {
            this.soundService.playSound(SOUND_NAMES.Correct, () => {
              selectedPanel.success = false;
              selectedPanel.active = false;
              this.soundService.playExpression(this.getExpression(3), () => {
                const burstBehind = this.letterPanelsActiveWord.find((lp, i) => i === Math.floor(this.letterPanelsActiveWord.length / 2));
                this.burst.animate({ soundEffect: SOUND_NAMES.Achievement, offsetId: burstBehind?.id }, BURST_TYPE.Round);
                this.subPartComplete.emit(false);
                this.emitCall.emit();
              });
            });
          } else {
            this.animatePanel(correctPanel, () => {
              correctPanel.success = false;
              selectedPanel.success = false;
              selectedPanel.active = false;
              this.subPartComplete.emit(false);
              this.emitCall.emit();
            });
          }
        });
      } else {
        this.soundService.playSound(SOUND_NAMES.Incorrect, () => {
          this.soundService.playExpression(this.getExpression(4), () => {
            selectedPanel.error = false;
            selectedPanel.active = false;
            this.validatingPuzzle = false;
          });
        });
      }
    }
  }

  private animatePanel(panel: ILetterPanel, callback?: () => void | undefined) {
    if (!panel) {
      callback ? callback() : undefined;
      return;
    }

    this.anim.animateTo(panel.id, this.targetPanel?.id, () => {
      this.targetPanel.complete = true;
      this.targetPanel.content = panel.content;
      callback ? callback() : undefined;
    });
  }

  isLastBeforeWord(index: number, array?) {
    if (array) {
      if (index === array.length - 1) return false;
      return array[index + 1].index === 0;
    } else {
      if (index === this.resultLetters.length - 1) return false;
      return this.resultLetters[index + 1].index === 0;
    }
  }

  private splitChainResult(array) {
    const segmentMax = 6;
    const result: any[] = [];

    let segment: any[] = [];
    let currentWord: any[] = [];

    array.forEach((lp, i) => {
      currentWord.push(lp);
      if (this.isLastBeforeWord(i) && currentWord.length > 0) {
        segment.push(...currentWord);
        currentWord = [];
        if (segment.length <= segmentMax) {
          result.push(segment);
          segment = [];
        }
      }

      if (currentWord.length <= 6 && lp.index === 0) {
        segment.push(...currentWord);
        currentWord = [];

        if (segment.length === segmentMax) {
          result.push(segment);
          segment = [];
        }
      }
    });

    if (currentWord.length > 0) {
      segment.push(...currentWord);
    }

    if (segment.length > 0) {
      result.push(segment);
    }

    return result;
  }

  resumePuzzle() {
    setTimeout(() =>{ 
      this.subPartComplete.emit({newPuzzle: true, passed: !this._selfCorrected});
      setTimeout(() =>{
        this.completePuzzle(this._selfCorrected ? PUZZLE_STATUS.FAIL : PUZZLE_STATUS.PASS);
      }, DELAY.S1)
    }, DELAY.S1);
    this.formatChain = [];
    this.resultLetters = [];
    return;
  }
}
