import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AudioRecordingService {
  recordAudio(): Promise<{ start: () => void; stop: () => Promise<{ audioBlob: Blob; audioUrl: string; play: () => void }> }> {
    return new Promise((resolve) => {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);
        const audioChunks: BlobPart[] = [];

        mediaRecorder.addEventListener('dataavailable', (event) => {
          audioChunks.push(event.data);
        });

        const start = () => {
          mediaRecorder.start();
        };

        const stop = (): Promise<{ audioBlob: Blob; audioUrl: string; play: () => void }> => {
          return new Promise((resolve) => {
            mediaRecorder.addEventListener('stop', () => {
              const audioBlob = new Blob(audioChunks, { type: 'audio/mpeg-3' });
              const audioUrl = URL.createObjectURL(audioBlob);
              const audio = new Audio(audioUrl);
              // play is only used for testing
              const play = () => {
                audio.play();
              };

              resolve({ audioBlob, audioUrl, play });
            });

            mediaRecorder.stop();
          });
        };

        resolve({ start, stop });
      });
    });
  }
}
