<ng-container *ngIf="appEvent$ | async as appEvent">
  <div id="overlay" class="overlay" *ngIf="this.showPage" aria-hidden="true">
    <div id="sound-card" class="sound-card" [ngClass]="{ visible: isReady }">
      <img [src]="soundCardImage" alt="Sound Card" />
    </div>

    <ng-container *ngIf="!this.animationComplete">
      <img aria-hidden="true" *ngFor="let obj of this.objects; let idx = index" [id]="'object' + idx" class="object"
        #object [ngSrc]="objectImage" alt="object" [ngClass]="{ 'last-object': idx === this.objects.length - 1 }"
        height="142" width="142" />
    </ng-container>

    <svg id="viewbox" viewBox="2 1 175 175">
      <path id="wave" d="M 200 155 130 90     M 130 90 A 40 40 0 1 0 50 90 A 40 40 0 1 0 130 90    M 130 90 240 0  " />
    </svg>
  </div>
</ng-container>