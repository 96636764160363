<div class="icon-list" [class.has-selection]="selectedIcon">
  <ng-container *ngFor="let icon of iconList; let i = index">
    <button
      class="icon-container block-{{ i }}"
      [class.selected]="selectedIcon && selectedIcon!.name === icon.name"
      (click)="selectIcon(icon)"
    >
      <img src="{{ icon.url }}" alt="{{ icon.name }}" [pTooltip]="icon.name" tooltipPosition="bottom" />
    </button>
  </ng-container>
</div>

<div class="form-footer">
  <div *ngIf="selectedIcon" class="login-button">
    <p-button label="Go" [loading]="loading" (onClick)="onSubmit()"></p-button>
  </div>
</div>
