<ng-container *ngIf="appEvent$ | async as appEvent">
  <div id="overlay" class="overlay" *ngIf="this.showPage">
    <div class="message">You did it!</div>
    <button
      [attr.data-automationId]="'play-again-button'"
      pButton
      icon="pi"
      label="Play again"
      styleClass="p-button-raised"
      class="play-again-button"
      (click)="onPlayAgain()"
      title="Play again"
    ></button>
  </div>
</ng-container>
