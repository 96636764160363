import { Component, DestroyRef, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { IAppEvent } from '@app/shared/types/app-event-interface';
import { AppStateService } from '@app/shared/services/app-state.service';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-play-again',
  standalone: true,
  imports: [CommonModule, ButtonModule],
  templateUrl: './play-again.component.html',
  styleUrls: ['./play-again.component.scss'],
  animations: [],
})
export class PlayAgainComponent {
  protected appEvent$: Observable<IAppEvent>;
  protected showPage = false;
  private _appStateService = inject(AppStateService);
  private _destroyRef = inject(DestroyRef);
  private callback;

  constructor() {
    this.appEvent$ = this._appStateService.appEvent$;

    this.appEvent$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((event) => {
      if (event.area === APP_EVENT_AREAS.PLAY_AGAIN) {
        this.showPage = true;
        this.callback = event.callback;
      }
    });
  }

  onPlayAgain() {
    this.showPage = false;
    this.callback ? this.callback() : undefined;
  }
}
