import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { IPasswordIcon } from '../../types/login.types';
import { KEY_EVENTS } from '@app/shared/constants/key-event-enums';

@Component({
  selector: 'app-icon-password',
  templateUrl: './icon-password.component.html',
  styleUrls: ['./icon-password.component.scss'],
})
export class IconPasswordComponent {
  @Input() iconList: IPasswordIcon[];
  @Input() loading: boolean;
  @Output() iconSelected = new EventEmitter<IPasswordIcon>();

  selectedIcon: IPasswordIcon | null;

  selectIcon(icon: IPasswordIcon | null) {
    this.selectedIcon = icon;
  }

  clearSelection() {
    this.selectedIcon = null;
  }

  onSubmit() {
    if (!this.selectedIcon) {
      return;
    }
    this.iconSelected.emit(this.selectedIcon);
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === KEY_EVENTS.Enter && this.selectedIcon) {
      this.onSubmit();
    }
  }
}
