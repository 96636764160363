import { NgModule } from '@angular/core';
import { DragDropModule } from 'primeng/dragdrop';
import { ToastModule } from 'primeng/toast';
import { MenubarModule } from 'primeng/menubar';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AccordionModule } from 'primeng/accordion';
import { FormsModule } from '@angular/forms';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { NgOptimizedImage } from '@angular/common';
import { ActivityCompleteComponent } from '@app/shared/components/activity-complete/activity-complete.component';
import { LoadingStateComponent } from '@app/shared/components/loading-state/loading-state.component';
import { FocusTrapModule } from 'primeng/focustrap';
import { WelcomeComponent } from '@app/shared/components/welcome/welcome.component';
import { PlayAgainComponent } from '@app/shared/components/play-again/play-again.component';
import { A11yModule } from '@angular/cdk/a11y';
import { HelpPanelComponent } from './shared/components/help-panel/help-panel.component';

@NgModule({
  providers: [],
  imports: [
    // UI
    ToastModule,
    DragDropModule,
    MenubarModule,
    InputTextModule,
    ButtonModule,
    AccordionModule,
    RadioButtonModule,
    FormsModule,
    ConfirmDialogModule,
    DialogModule,
    NgOptimizedImage,
    HelpPanelComponent,
    ActivityCompleteComponent,
    WelcomeComponent,
    LoadingStateComponent,
    PlayAgainComponent,
    FocusTrapModule,
    A11yModule,
  ],
  exports: [
    // UI
    ToastModule,
    DragDropModule,
    MenubarModule,
    InputTextModule,
    ButtonModule,
    AccordionModule,
    RadioButtonModule,
    FormsModule,
    ConfirmDialogModule,
    DialogModule,
    NgOptimizedImage,
    HelpPanelComponent,
    ActivityCompleteComponent,
    WelcomeComponent,
    LoadingStateComponent,
    PlayAgainComponent,
    FocusTrapModule,
    A11yModule,
  ],
})
export class UIModule {}
