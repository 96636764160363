import { ILetterPanel } from '@app/shared/components/letter-panel/types/letter-panel-interface';
import {
  APOSTROPHE,
  AT_SYMBOL,
  COMMA_SEPARATOR,
  DOUBLE_UNDERSCORE,
  LEFT_BRACE,
  LEFT_BRACKET,
  LEFT_PARENTHESES,
  LETTER_SEPARATOR,
  RIGHT_BRACE,
  RIGHT_BRACKET,
  RIGHT_PARENTHESES,
  SYLLABLE_SEPARATOR,
} from '@app/shared/constants/activity-constants';
import { environment } from '@env/environment';
import './prototypes';

export interface IWordDifferences {
  diff1: string;
  diff2: string;
  same: string;
}

export class AppHelpers {
  public static generateId() {
    return self.crypto.randomUUID();
  }

  public static isVowel(content: string | undefined) {
    if (!content) {
      return false;
    }
    const vowels = [
      'a',
      'e',
      'i',
      'o',
      'u',
      'ar',
      'er',
      'ir',
      'or',
      'ur',
      'ai',
      'ay',
      'eigh',
      'ei',
      'ee',
      'ey',
      'ie',
      'ea',
      'igh',
      'oa',
      'oe',
      'ow',
      'ou',
      'ui',
      'ue',
      'ew',
      'oo',
      'oi',
      'oy',
      'au',
      'aw',
    ];

    if (content.includes(AT_SYMBOL)) {
      return true; // example @y in Fry
    }

    const letter = content ? this.cleanWrappedCharacters(content) : '';

    return vowels.includes(letter.toLowerCase());
  }

  public static isDefined<T>(value: T | undefined): value is T {
    return value !== undefined;
  }

  public static getFirstDifference = (s1: string, s2: string) => {
    const s1Chars = [...s1.split(LETTER_SEPARATOR)];
    const s2Chars = [...s2.split(LETTER_SEPARATOR)];
    let diff: string | undefined;
    if (s1.length >= s2.length) {
      diff = s1Chars.find((char, i) => char !== s2Chars[i]);
    } else {
      diff = s2Chars.find((char, i) => char !== s1Chars[i]);
    }

    return diff ? diff : '';
  };

  /**
   * @deprecated
   * no longer in use since answers are marked as correct in the dataset
   */
  public static getWordDifferences(s1: string, s2: string, subtraction = false, addition = false): IWordDifferences {
    let diff1 = '';
    let diff2 = '';
    let same = '';

    let s1Chars = [...s1.split(LETTER_SEPARATOR)];
    let s2Chars = [...s2.split(LETTER_SEPARATOR)];

    const length = Math.min(s1.length, s2.length);

    if (subtraction) {
      const length = Math.max(s1Chars.length, s2Chars.length);
      for (let i = 0; i < length; i++) {
        if (s1Chars[i] !== s2Chars[i]) {
          diff1 += s1Chars[i] || '';
          s1Chars = s1Chars.splice(i, 1);
        } else {
          if (s1Chars[i]) {
            same += s1Chars[i];
          }
        }
      }
    } else if (addition) {
      const length = Math.max(s1Chars.length, s2Chars.length);
      for (let i = 0; i < length; i++) {
        if (s2Chars[i] !== s1Chars[i]) {
          diff2 += s2Chars[i] || '';
          s2Chars = s2Chars.splice(i, 1);
        } else {
          if (s2Chars[i]) {
            same += s2Chars[i];
          }
        }
      }
    } else {
      for (let i = 0; i < length; i++) {
        if (s1Chars[i] !== s2Chars[i]) {
          diff1 += s1Chars[i] || '';
          diff2 += s2Chars[i] || '';
        } else {
          if (s1Chars[i]) {
            same += s1Chars[i];
          }
        }
      }
    }

    // Append remaining characters from longer string to corresponding diff
    diff1 += s1Chars.slice(length);
    diff2 += s2Chars.slice(length);

    diff1 = this.cleanWrappedCharacters(diff1);
    diff2 = this.cleanWrappedCharacters(diff2);
    same = this.cleanWrappedCharacters(same);

    return {
      diff1,
      diff2,
      same,
    };
  }

  static buildChoicePanels(word: string, letterPanels: ILetterPanel[], indexStart = 0) {
    const stripBrackets = (str) => (str.startsWith('[') && str.endsWith(']') ? str.substring(1, str.length - 1) : str);
    const syllables = word.replaceAll(COMMA_SEPARATOR, LETTER_SEPARATOR).split(SYLLABLE_SEPARATOR);

    let letterArray = word
      .split(',')
      .map((letter) => letter.trim())
      .sort((a, b) => {
        const aSort = stripBrackets(a);
        const bSort = stripBrackets(b);

        const isVowelA = AppHelpers.isVowel(aSort);
        const isVowelB = AppHelpers.isVowel(bSort);
        const startsWithLeftParenthesesA = aSort.startsWith('(');
        const startsWithLeftParenthesesB = bSort.startsWith('(');
        const startsWithLeftCurlyBracesA = aSort.startsWith('{');
        const startsWithLeftCurlyBracesB = bSort.startsWith('{');
        const isLineA = aSort.includes(DOUBLE_UNDERSCORE);
        const isLineB = bSort.includes(DOUBLE_UNDERSCORE);

        if (isLineA) {
          return 1;
        } else if (isLineB) {
          return -1;
        } else if (startsWithLeftCurlyBracesA && startsWithLeftCurlyBracesB) {
          return aSort.localeCompare(bSort);
        } else if (startsWithLeftCurlyBracesA) {
          return 1;
        } else if (startsWithLeftCurlyBracesB) {
          return -1;
        } else if (isVowelA && isVowelB) {
          return aSort.localeCompare(bSort);
        } else if (isVowelA) {
          return 1;
        } else if (isVowelB) {
          return -1;
        } else if (startsWithLeftParenthesesA && startsWithLeftParenthesesB) {
          return aSort.localeCompare(bSort);
        } else if (startsWithLeftParenthesesA) {
          return 1;
        } else if (startsWithLeftParenthesesB) {
          return -1;
        } else {
          return aSort.localeCompare(bSort);
        }
      });

    const resultArray: any[] = [];
    letterArray.forEach((element) => {
      if (/^[a-zA-Z]_[a-zA-Z]$/.test(element)) {
        resultArray.push(element[0], element[1], element[2]);
      } else if (/^\[[a-zA-Z]_[a-zA-Z]\]$/.test(element)) {
        // Checks if the pattern matches
        // If the pattern matches, split the element and add each part to the resultArray
        resultArray.push(element[1], element[2], element[3]);
      } else {
        // If the pattern does not match, add the element as is
        resultArray.push(element);
      }
    });
    letterArray = resultArray;

    letterPanels.length = 0;

    letterArray.forEach((letter, index) => {
      letterPanels.push({
        index: indexStart + index,
        content: this.cleanWrappedCharacters(letter),
        stagedContent: '',
        suffix: letter.includes(LEFT_PARENTHESES) || letter.includes(RIGHT_PARENTHESES),
        gluedSound: letter.includes(LEFT_BRACE) || letter.includes(RIGHT_BRACE),
        syllableIndex: index,
        isCorrectAnswer: letter.includes(LEFT_BRACKET),
        vowel: AppHelpers.isVowel(letter) || letter.includes(APOSTROPHE),
        isChoice: true,
        isWhiteBox: this.cleanWrappedCharacters(letter) === DOUBLE_UNDERSCORE,
      });
    });

    return syllables;
  }

  static buildTargetWordPanels(word: string, letterPanels: ILetterPanel[], indexStart = 0) {
    if (!word.includes(`-`)) {
      word = word.split('').join('-');
    }
    word = word.trimAll();
    const syllables = word
      .replaceAll(COMMA_SEPARATOR, LETTER_SEPARATOR)
      .split(/\||(?=-\()/)
      .map((s) => s.replace(/^-/, ''));

    letterPanels.length = 0; // clear
    let tempIndex = 0;
    let valIndex;
    syllables.forEach((s, syllableIndex) => {
      s.split(LETTER_SEPARATOR).forEach((letter, idx) => {
        if (tempIndex > 0) {
          valIndex = tempIndex += 1;
        } else {
          valIndex = indexStart + idx;
        }
        letterPanels.push({
          index: valIndex,
          content: this.cleanWrappedCharacters(letter),
          stagedContent: '',
          suffix: letter.includes(LEFT_PARENTHESES) || letter.includes(RIGHT_PARENTHESES),
          vowel: AppHelpers.isVowel(letter),
          gluedSound: letter.includes(LEFT_BRACE) || letter.includes(RIGHT_BRACE),
          syllableIndex: syllableIndex,
          isCorrectAnswer: letter.includes(LEFT_BRACKET),
        });
        if (idx === s.split(LETTER_SEPARATOR).length - 1) {
          tempIndex = idx;
        }
      });
    });
    return syllables;
  }

  static randomNumber(min, max) {
    return Math.floor(min + Math.random() * (max - min + 1));
  }

  static shuffleArray(theArray: any[]) {
    for (let i = theArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * i);
      const temp = theArray[i];
      theArray[i] = theArray[j];
      theArray[j] = temp;
    }
  }

  public static cleanWrappedCharacters(content: string) {
    return content
      .clean(LEFT_PARENTHESES)
      .clean(RIGHT_PARENTHESES)
      .clean(LEFT_BRACE)
      .clean(RIGHT_BRACE)
      .clean(LETTER_SEPARATOR)
      .clean(LEFT_BRACKET)
      .clean(RIGHT_BRACKET)
      .clean(AT_SYMBOL);
  }

  public static removeFirstOccurrence(text: string, targetString: string) {
    const index = text.indexOf(targetString);
    if (index === -1) {
      return text;
    }
    return text.slice(0, index) + text.slice(index + targetString.length);
  }

  public static isSafari() {
    const safariAgent = navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') < 0;
    return safariAgent;
  }

  public static isDebugMode() {
    const urlParams = new URLSearchParams(window.location.toLocaleString());
    return urlParams.has('debug');
  }

  public static isRecording(): boolean {
    const urlParams = new URLSearchParams(window.location.toLocaleString());
    return urlParams.has('record');
  }

  public static isActivitiesBypass(): boolean {
    return window.location.href.includes('activities-bypass');
  }

  public static autoCompletePuzzles() {
    if (environment.production) {
      return -1;
    }

    const urlParams = new URLSearchParams(window.location.toLocaleString());
    const targetPuzzle = urlParams.get('tp'); // auto complete up to puzzle n
    if (!targetPuzzle || targetPuzzle === '0') {
      return -1;
    }

    return Number(targetPuzzle) - 1;
  }
}
