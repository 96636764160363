<div id="pt1">
  <ng-container class="active-word-area">
    <div class="active-word" [ngClass]="{
      'grid' :  currentLevel !== puzzleLevelEnum.Domino,
      'grid-nogutter': currentLevel !== puzzleLevelEnum.Domino,
      'border': currentLevel === puzzleLevelEnum.Domino
    }">
      <div *ngFor="let letter of letterPanelsActiveWord" class="active-letter">
        <app-letter-panel-component [letterPanel]="letter" [text]="currentLevel === puzzleLevelEnum.Domino"></app-letter-panel-component>
      </div>
    </div>
  </ng-container>
  <app-choice-bank
    [choices]="this.letterPanelsChoices"
    [validatingPuzzle]="validatingPuzzle"
    *ngIf="this.letterPanelsChoices.length > 0"
    (chosenPanel)="onLetterChoiceSelected($event)"
  >
  </app-choice-bank>
</div>
