<div class="loading-overlay" *ngIf="isLoading$ | async">
  <div class="container">
    <div class="circles">
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
    </div>

    <div class="progress-bar">
      <div class="progress"></div>
    </div>
  </div>
</div>
