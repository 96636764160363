import { Component, DestroyRef, OnInit } from '@angular/core';
import { IDashboardResponse } from './types/assignment.types';
import { AssignmentService } from './services/assignment.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '@env/environment';
import { CurrentUserService } from '@app/shared/services/current-user.service';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [AssignmentService],
})
export class DashboardComponent implements OnInit {
  protected activityTiles: IDashboardResponse[];
  protected instructionsLinkText = 'How do I play?';
  protected tutorialModalVisible = false;
  protected activeTutorialUrl: string = '';
  protected thumbnailBaseUrl: string;
  protected showLoading = true;

  constructor(
    private _destroyRef: DestroyRef,
    private _assignmentService: AssignmentService,
    private _currentUserService: CurrentUserService
  ) {}

  ngOnInit(): void {
    this.thumbnailBaseUrl = `${environment.CDN_URL}/activities/app-assets/card-`;
    this.initActivityTiles();
  }

  initActivityTiles(): void {
    const user = this._currentUserService.currentUser;
    this._assignmentService
      .getStudentAssignments(user.id, user.teacherPasscode)
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((activities: IDashboardResponse[]) => {
        this.activityTiles = activities;
        this.showLoading = false;
      });
  }

  toggleTutorialVideoModal(event: Event, videoUrl = ''): void {
    event.stopPropagation();
    this.tutorialModalVisible = videoUrl.length > 0;
    this.activeTutorialUrl = videoUrl;
  }

  openActivity(tile: IDashboardResponse): void {
    window.location.assign(tile.activityUrl);
  }
}
