<ng-container *ngIf="appEvent$ | async as appEvent">
  <div id="overlay" class="overlay" *ngIf="showPage" aria-hidden="true" [ngClass]="{ fullMode: isFullMode }">
    <div class="header">
      <i
        class="pi"
        (click)="toggleVisibility()"
        title="Toggle Visibility"
        [ngClass]="{ 'pi-chevron-left': isFullMode, 'pi-chevron-right': !isFullMode }"
      ></i>
      <i class="pi pi-undo" *ngIf="isFullMode" (click)="clear()" title="Clear Messages"></i>
      <span class="title" *ngIf="isFullMode">Debug Console</span>
    </div>

    <ol *ngIf="isFullMode">
      <li class="info-message" *ngFor="let msg of messages; let idx = index">
        <span class="time">{{ msg.time }}</span> <span class="message">{{ msg.message }}</span>
        <ngx-json-viewer *ngIf="msg.object !== null" [json]="msg.object" [expanded]="false"></ngx-json-viewer>
      </li>
    </ol>
  </div>
</ng-container>
