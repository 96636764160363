import { environment } from '@env/environment';

const base = `${environment.API_URL}`;
const loginBaseUrl = `${base}/student-authentication`;
const authBaseUrl = `${base}/authentication`;
const audioBaseUrl = `${base}/audio`;
const userBaseUrl = `${base}/user`;
const eventTrackingUrl = `${base}/event-tracking`;

export const API_ENDPOINTS = {
  validateTeacherPasscode: `${loginBaseUrl}/teacher`,
  validateStudentInitials: `${loginBaseUrl}/initials`,
  validateStudentIconPasscode: `${loginBaseUrl}/auth-token`,
  submitActivitySession: `${base}/activity-session/session`,
  resumeActivitySession: `${base}/activity-session/session-resume`,
  validateUrl: `${authBaseUrl}/auth-cookie`,
  setXsrf: `${authBaseUrl}/xsrf`,
  loginClever: `${authBaseUrl}/login`,
  loginCleverSuccess: `${authBaseUrl}/oidc-login-success`,
  logoutClever: `${authBaseUrl}/logout`,
  audioSession: `${audioBaseUrl}/audio-session`,
  getStudentAssignments: `${base}/student-dashboard/dashboard`,
  currentUser: `${userBaseUrl}/current-user`,
  logout: `${loginBaseUrl}/logout`,
  validatePreviewToken: `${authBaseUrl}/teacher-preview`,
  validateSessionData: `${base}/activity-session/validate`,
  isAuthenticated: `${loginBaseUrl}/isAuthenticated`,
  trackEvent: `${eventTrackingUrl}/track`
};
