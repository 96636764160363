<ng-container *ngIf="appEvent$ | async as appEvent">
  <div id="overlay" data-cy="activity-complete" class="overlay" *ngIf="this.showPage">
    <div class="burst-area" id="burst-1"></div>
    <div class="banner"></div>
    <div id="bucket-host" class="bucket-host grid"
      [ngClass]="{ visible: this.scoreRange !== SCORE_RANGE_ENUM.UNKNOWN , 'level-1': this.level === 1,'level-2': this.level === 2,'level-3': this.level === 3}">
      <img id="bucket-1" class="bucket col" [src]="
          this.scoreRange === SCORE_RANGE_ENUM.LOW ||
          this.scoreRange === SCORE_RANGE_ENUM.MEDIUM ||
          this.scoreRange === SCORE_RANGE_ENUM.HIGH
            ? this.bucketImage1
            : this.bucketShadowImage
        " alt="bucket" />
      <img id="bucket-2" class="bucket col" [src]="
          this.scoreRange === SCORE_RANGE_ENUM.MEDIUM || this.scoreRange === SCORE_RANGE_ENUM.HIGH
            ? this.bucketImage2
            : this.bucketShadowImage
        " alt="bucket" />
      <img id="bucket-3" class="bucket col"
        [src]="this.scoreRange === SCORE_RANGE_ENUM.HIGH ? this.bucketImage3 : this.bucketShadowImage" alt="bucket" />
    </div>
    <div class="burst-area" id="burst-2"></div>
  </div>
  <div *ngIf="showBackToDashboardDialog" class="dialog-mask">
    <div class="dialog text-center">
      <img class="header-icon" src="/assets/home-icon.svg" alt="Home Icon" />
      <h2>Return to Dashboard</h2>
      <p>Go back to your dashboard to see what's next.</p>
      <div class="cta-sound-combo mt-4">
        <button class="btn-primary" (click)="backToDashboard()">Back to Dashboard</button>
        <button class="t2s-btn bottom-right" (click)="playTextToSpeech()" [disabled]="isTextToSpeechPlaying$ | async">
          <img *ngIf="isTextToSpeechPlaying$ | async" src="/assets/text-to-speech-inactive.svg"
            alt="text-to-speech disabled button" />
          <img *ngIf="(isTextToSpeechPlaying$ | async) === false" src="/assets/text-to-speech-active.svg"
            alt="text-to-speech button" pTooltip="Read popup content" />
        </button>
      </div>
    </div>
  </div>
</ng-container>