import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  setItems(key: string, value: any, itemsToKeep?: number) {
    const items = this.getItems(key);
    items.push(value);

    // Keep only the last X items?
    if (itemsToKeep) {
      if (items.length > itemsToKeep) {
        items.shift(); // Remove the oldest
      }
    }

    localStorage.setItem(key, JSON.stringify(items));
  }

  getItems(key: string): string[] {
    const items = localStorage.getItem(key);
    return items ? JSON.parse(items) : [];
  }

  removeItem(key: string, value?: string): void {
    const items = this.getItems(key);
    // check for array
    if (Array.isArray(items) && value) {
      const index = items.indexOf(value);
      if (index !== -1) {
        items.splice(index, 1);
        localStorage.setItem(key, JSON.stringify(items));
      }
    } else {
      localStorage.removeItem(key);
    }
  }

  removeAllItems(key: string): void {
    localStorage.removeItem(key);
  }
}
