<div id="play-button-container">
  <button
    id="play-button"
    class="image-button round"
    aria-label="Click to Play!"
    (click)="onPlayClick()"
    [attr.data-automationId]="'play-button'"
    trackClick="FHPStartedActivity"
  >
    <img [ngSrc]="playButtonImage" alt="play button" height="75" width="75" aria-hidden="true" />
    <img id="play-cursor" [ngSrc]="playButtonCursorImage" alt="click cursor icon" height="73" width="68" aria-hidden="true" />
  </button>
</div>
