<div id="pt0">
  <ng-container class="active-word-area">
    <div id="container">
      <img class="tap-icon" src="assets/play-icon.svg" alt="tap icon" />
      <div class="active-word grid grid-nogutter" id="active-word">
        <div *ngFor="let letter of letterPanelsActiveWord" class="active-letter">
          <app-letter-panel-component [letterPanel]="letter" (chosenPanel)="onLetterChoiceSelected($event)"></app-letter-panel-component>
          <img class="sound-icon" src="assets/play-icon.svg" alt="sound icon" pTooltip="Listen" />
        </div>
      </div>
    </div>
  </ng-container>
</div>
