import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';

import { AnimationHelpers } from '@app/shared/helpers/animation';
import { ANIMATIONS } from '@app/shared/constants/animation-enums';
import { DELAY } from '@app/shared/constants/delay-enums';
import { PUZZLE_STATUS } from '@app/shared/constants/session-enums';
import { IWorldOfWordsDataset } from '../../types/world-of-words-dataset-interface';
import { AppStateService } from '@app/shared/services/app-state.service';
import { IPuzzleCompleteEvent } from '@app/shared/types/puzzle-complete-event-interface';
import { PuzzleTypeBaseComponent } from '@app/pages/activities/puzzle-type-base/puzzle-type-base.component';
import { AppHelpers } from '@app/shared/helpers/app-helpers';
import { AudioRecordingService } from '@app/shared/services/audio-recording.service';
import { ActivitySessionService } from '@app/shared/services/activity-session.service';

@Component({
  selector: 'app-wow-puzzle-type-2',
  templateUrl: './puzzle-type-2.component.html',
  styleUrls: ['./puzzle-type-2.component.scss'],
  imports: [CommonModule, NgOptimizedImage],
  standalone: true,
  providers: [],
})
export class WOWPuzzleType2Component extends PuzzleTypeBaseComponent {
  @Input() currentWorldOfWordsDataset: IWorldOfWordsDataset | undefined;
  @Input() expressions: Map<number, string>;
  @Output() puzzleComplete = new EventEmitter<IPuzzleCompleteEvent>();

  protected isPuzzleSolved = false;

  private _appState = inject(AppStateService);
  private _audioRecordingService = inject(AudioRecordingService);
  private _activitySessionService = inject(ActivitySessionService);

  // Puzzle Type 2
  protected finalParagraphReview: string;
  protected rightButtonImage = '';
  protected isRecording: boolean = false;
  protected currentLevel: number | undefined = this._appState.level;
  protected micButtonImage = '/assets/icons/text-to-speech.svg';

  constructor() {
    super();
    this.isRecording = AppHelpers.isRecording();
    this.emitCall = this.puzzleComplete;
  }

  initPuzzle() {
    this.setExpressions(this.expressions);
    this.setMaxTries(this.currentWorldOfWordsDataset?.maxTries)
    this.startPuzzleMetrics();
    // show paragraph, then wait for 1 minute to show continue button
    this.finalParagraphReview = this.currentWorldOfWordsDataset?.sentence ? this.currentWorldOfWordsDataset?.sentence : '';
    setTimeout(() => {
      this.rightButtonImage = `/assets/arrow-right-dark-${this.currentLevel}.svg`;
      setTimeout(() => {
        AnimationHelpers.animate('continue-button', ANIMATIONS.Pulse);
      }, DELAY.S1);
    }, DELAY.S5);
  }

  async doRecord() {
    await this.recordAudio(DELAY.S10, "Recording session started");
  }

  private async recordAudio(delay: number, expression: string): Promise<void> {
    if (!AppHelpers.isRecording()) return;

    const recorder = await this._audioRecordingService.recordAudio();
    recorder.start();

    setTimeout(async () => {
      const recording = await recorder.stop();
      await this._activitySessionService.processAudio(recording.audioBlob, expression);
      this.isRecording = false;
    }, delay);

  }

  onNextPuzzle() {
    this.completePuzzle(PUZZLE_STATUS.PASS, this.currentWorldOfWordsDataset?.sentence ?? ''); // pass paragraph to parent as its needed in pt 3);
  }
}
