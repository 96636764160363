import { CommonModule, NgOptimizedImage } from '@angular/common';
import { LetterPanelComponent } from '@app/shared/components/letter-panel/letter-panel.component';
import { FooterComponent } from '@app/shared/components/footer/footer.component';
import { Component, DestroyRef, ViewChild, inject } from '@angular/core';
import { SoundService } from '@app/shared/services/sound.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivityBaseComponent } from '../activity-base/activity-base.component';
import { DELAY } from '@app/shared/constants/delay-enums';
import { IMakeAChangeDataset } from './types/make-a-change-dataset-interface';
import { MACPuzzleType0Component } from './puzzle-types/0/puzzle-type-0.component';
import { MACPuzzleType1Component } from './puzzle-types/1/puzzle-type-1.component';
import { MACPuzzleType2Component } from './puzzle-types/2/puzzle-type-2.component';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';
import { ProgressBarComponent } from '@app/shared/components/progress-bar/progress-bar.component';

enum PUZZLE_TYPE {
  Blank = 0,
  Basic,
  Bank,
  Unknown,
}

export enum LEVEL {
  ZIPPY = 1,
  OLLIE = 2,
  DOMINO = 3,
}

@Component({
  selector: 'app-make-a-change-activity',
  templateUrl: './make-a-change-activity.component.html',
  styleUrls: ['./make-a-change-activity.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FooterComponent,
    LetterPanelComponent,
    NgOptimizedImage,
    MACPuzzleType0Component,
    MACPuzzleType1Component,
    MACPuzzleType2Component,
    ProgressBarComponent
  ],
})
export class MakeAChangeActivityComponent extends ActivityBaseComponent {
  protected macDatasets: IMakeAChangeDataset[];
  protected currentMacDataset: IMakeAChangeDataset | undefined;
  protected totalPuzzles: number = 0;

  protected displayPB: boolean = false;
  
  protected currentGroup: number;
  protected currentGroupSet: IMakeAChangeDataset[];
  protected newGroup: boolean;

  protected puzzleTypeEnum = PUZZLE_TYPE;
  protected puzzleType: PUZZLE_TYPE;

  // PT 0
  @ViewChild(MACPuzzleType0Component)
  private _puzzleType0: MACPuzzleType0Component;
  // PT 1
  @ViewChild(MACPuzzleType1Component)
  private _puzzleType1: MACPuzzleType1Component;
  // PT 2
  @ViewChild(MACPuzzleType2Component)
  private _puzzleType2: MACPuzzleType2Component;

  private _destroyRef = inject(DestroyRef);
  private _soundService = inject(SoundService);

  @ViewChild(ProgressBarComponent)
  private _progressBarComp!: ProgressBarComponent;

  constructor() {
    super();
  }

  processKeyEvent(event: KeyboardEvent) { }

  initActivity(callback?: () => void | undefined): void {
    this.loadDataset()
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((datasetSrc) => {
        this.macDatasets = datasetSrc as IMakeAChangeDataset[];
        this.totalPuzzles = this.macDatasets.length;
        this.currentMacDataset = this.macDatasets?.filter((d) => !d.completed)[0];
        this.currentGroup = this.currentMacDataset?.group;
        this.currentGroupSet = this.macDatasets.filter((d) => d.group === this.currentGroup);
        this.newGroup = true;
        this.markerCount = Number(this.macDatasets?.at(-1)?.group);
        callback ? callback() : undefined;

        this.listenForJumpToPuzzle();
      });

    this.listenForCompleteAllPuzzles();
  }

  initPuzzle() {
    if (!this.currentMacDataset) {
      this.showActivitySolved();
      return;
    }

    this.puzzleType = this.currentMacDataset.puzzleType || 0;
    this.buildExpressions(this.currentMacDataset);

    this.displayPB = true;
    this.appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.PROFILE_NARRATOR,
    });

    this._progressBarComp.markedComplete(this.macDatasets.filter((d) => d.completed)?.length)

    setTimeout(() => {
      switch (this.puzzleType) {
        case PUZZLE_TYPE.Blank:
          this._puzzleType0.initPuzzle();
          break;
        case PUZZLE_TYPE.Basic:
          this._puzzleType1.initPuzzle();
          break;
        case PUZZLE_TYPE.Bank:
          this._puzzleType2.initPuzzle();
          break;
        default:
          break;
      }
    }, DELAY.ZERO);
  }

  getNextDataset() {
    this.updateSession();

    if (this.currentMacDataset) {
      this.currentMacDataset.completed = true;
      this.currentMacDataset = this.macDatasets.filter((be) => !be.completed)[0];
      if (!this.currentMacDataset) {
        this.showActivitySolved();
        return;
      }

      if (this.currentMacDataset.group === this.currentGroup) {
        this.newGroup = false;
        this.initPuzzle();
        return;
      } else {
          if (this.currentMacDataset) this.currentGroup = this.currentMacDataset.group;
          this.currentGroupSet = this.macDatasets.filter((d) => d.group === this.currentGroup);
          this.newGroup = true;
          this.initPuzzle();
          return;
      }
    }
  }

  showActivitySolved() {
    this._progressBarComp.progressBarComplete(() => {
      this.activitySolved = true;
      this.puzzleType = PUZZLE_TYPE.Unknown;
      this._progressBarComp.reset();

      this.playEndExpression();
      this.toggleActivityCompletePage(true);
    });
  }

  startActivity() {
    if (this.activitySolved) {
      this.activitySolved = false;
      this.toggleActivityCompletePage(false);
    }

    this.initActivity(() => {
      const score = Array.from(new Map(this.macDatasets.map((el) => [el.group, el])).values());
      this.setPerfectScore(score.length || 0);

      this.soundService.playExpression(this.currentActivity.startExpression, () => {
        this.initPuzzle();
      });
    });
  }

  onPuzzleComplete() {
    this.getNextDataset();
  }

  incrementProgress(data){
    this._progressBarComp.proceed(()=>{},data.passed)
  }

  private listenForJumpToPuzzle() {
    this.appStateService.appEvent$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((event) => {
      if (event.area === APP_EVENT_AREAS.JUMP_TO_PUZZLE) {
        if (event.payload < this.macDatasets.length) {
          this.currentMacDataset = this.macDatasets[event.payload];
          this.initPuzzle();
        }
      }
    });
  }
  
  private listenForCompleteAllPuzzles(): void {
    this.appStateService.appEvent$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((event) => {
      if (event.area === APP_EVENT_AREAS.COMPLETE_ALL_PUZZLES) {
        this.completeAllPuzzles();
        this.updateActivityAfterCompletion();
      }
    });
  }
  
  private updateActivityAfterCompletion(): void {
    // Check if all puzzles are completed and update the UI accordingly
    this.currentMacDataset = this.macDatasets?.find((d) => !d.completed);
    if (!this.currentMacDataset) {
      this.showActivitySolved(); // Show activity completed if all puzzles are done
    } else {
      this.initPuzzle(); // Reinitialize the current puzzle if there are any left
    }
  }
}
