import { Component, DestroyRef, OnInit, ViewChild } from '@angular/core';
import { LoginService } from '@app/pages/login/services/login.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  IPasswordIcon,
  IStudentIconPasscodeValidationResponse,
  IStudentInitialsValidationResponse,
  IStudentLoginRequest,
  ITeacherPasscodeValidationResponse,
} from '@app/pages/login/types/login.types';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginFormComponent } from '@app/pages/login/components/login-form/login-form.component';
import { IconPasswordComponent } from '@app/pages/login/components/icon-password/icon-password.component';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { LocalStorageService } from '@app/shared/services/local-storage.service';
import { SoundService } from '@app/shared/services/sound.service';
import { environment } from '@env/environment';

enum LOGIN_STEPS {
  SELECTION,
  TEACHER_PASSCODE,
  INITIALS,
  STUDENT_PASSCODE,
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [LoginService],
})
export class LoginComponent implements OnInit{
  @ViewChild('passcodeComponent') private _passcodeComp: LoginFormComponent;
  @ViewChild('passwordComponent') private _passwordComp: IconPasswordComponent;
  protected loginSteps = LOGIN_STEPS;
  // change me back
  protected currentStep = LOGIN_STEPS.SELECTION;
  protected isLoading = false;
  // could change to a teacher object
  protected teacher: {
    teacherPasscode: string;
    teacherName: string;
  };
  // could change to a student object
  protected studentInitials = '';

  // dialogs
  protected showHelpDialog = false;
  protected showIncorrectDialog = false;
  protected incorrectDialogHeader = '';
  protected showInvalidCleverLoginDialog = false;
  isSoundPlaying$: BehaviorSubject<boolean>;

  protected parentIconList: IPasswordIcon[];

  constructor(
    private _localStorageService: LocalStorageService,
    private _loginService: LoginService,
    private _soundService: SoundService,
    private _destroyRef: DestroyRef,
    private _router: Router,
    private _route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.isSoundPlaying$ = this._soundService.soundIsPlaying$;
    this.checkForInvalidCleverLogin();
  }

  stepForward() {
    if (this.currentStep < LOGIN_STEPS.STUDENT_PASSCODE) {
      this.currentStep++;
    }
    if(this._soundService.soundIsPlaying$.value){
      this._soundService.stopExpression();
    }
    return;
  }

  stepBack() {
    if (this.currentStep > LOGIN_STEPS.SELECTION) {
      this.currentStep--;
    }
        if(this._soundService.soundIsPlaying$.value){
      this._soundService.stopExpression();
    }
    return;
  }

  submitTeacherPasscode(passcode: string) {
    this.isLoading = true;
    this._loginService
      .validateTeacherPasscode(passcode.toUpperCase())
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((res: ITeacherPasscodeValidationResponse | null) => {
        if (res === null) {
          this.showIncorrectDialog = true;
          this.incorrectDialogHeader = 'teacher passcode';
          this._passcodeComp.clearInputs();
        } else {
          this.teacher = res;
          this.stepForward();
        }
        this.isLoading = false;
      });
  }

  submitStudentInitials(initials: string) {
    this.isLoading = true;
    this._loginService
      .validateStudentInitials(initials.toUpperCase(), this.teacher.teacherPasscode.toUpperCase())
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((res: IStudentInitialsValidationResponse | null) => {
        if (res === null) {
          this.showIncorrectDialog = true;
          this.incorrectDialogHeader = 'initials';
        } else {
          this.studentInitials = res.studentInitials;
          this.parentIconList = res.passwordIcons;
          this.stepForward();
        }
        this.isLoading = false;
      });
  }

  submitStudentIconPasscode(password: number) {
    this.isLoading = true;
    const request: IStudentLoginRequest = {
      password: password,
      initials: this.studentInitials,
      teacherPasscode: this.teacher.teacherPasscode,
    };

    this._loginService
      .validateStudentIconPasscode(request)
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(
        async (res: IStudentIconPasscodeValidationResponse | null) => {
          if (res) {
            await lastValueFrom(this._loginService.setXsrfAsync());
            this._router.navigate(['']);
          }
          this.isLoading = false;
        },
        (error: any) => {
          this.showIncorrectDialog = true;
          this.incorrectDialogHeader = 'password';
          this.isLoading = false;
          this._passwordComp.clearSelection();
        }
      );
  }

  loginClever(): void {
    this._loginService.loginClever(`${window.location.origin}/login-success`).subscribe();
  }

  closeCleverErrorDialog(loginWithPassword = false): void {
    // Remove the query params
    this._router.navigate([], {
      queryParams: {},
    });
    if (loginWithPassword) this.stepForward();
    this.showInvalidCleverLoginDialog = false;
  }

  playCleverErrorAudio(): void {
   
    this._soundService.playFile(
      `${environment.CDN_URL}/student/audio/5F6026BDC9E02025A3A1CA88CCEC9FC8BCB77C2E5953B2806428106DC6009602.mp3`,
      1,
      false
    );
  }

  playSelectLoginAudio(){
   
    this._soundService.playFile(
      `${environment.CDN_URL}/student/audio/8D5E71323FC659D29B28582EF6C101B5C5F480B77978FFD1BE9012F2B2771461.mp3`,
      1,
      false
    );
  }

  playTeacherPasscodeAudio(){
   
    this._soundService.playFile(
      `${environment.CDN_URL}/student/audio/77C7074ADCAEFD9DBBA3B998B94BD249A625BFF4EC141EA585322E52BCC8633B.mp3`,
      1,
      false
    );
  }

  playChangeTeacherAudio(){
   
    this._soundService.playFile(
      `${environment.CDN_URL}/student/audio/7F7ACD2CAED35C5250BB4F093C69EB85B832F3E29B0DA0778D1492BC987F3B79.mp3`,
      1,
      false
    );  
  }

  playEnterInitialAudio(){
   
    this._soundService.playFile(
      `${environment.CDN_URL}/student/audio/D3ABA11A15B2C86DF523449B292E2295D07636BF833C9BEA23212F7F1324149A.mp3`,
      1,
      false
    );
  }

  playChangeStudentAudio(){
   
    this._soundService.playFile(
      `${environment.CDN_URL}/student/audio/009914E6B8119F62F353C0D3B311972D348CA3E994CF809101FD3AA9C887D123.mp3`,
      1,
      false
    )
  }

  playStudentPasswordAudio(){
   
    this._soundService.playFile(
      `${environment.CDN_URL}/student/audio/30BF3C70F50F57E81C391927E19785934617F82AD76EA5E5E2E3BD2ED7314753.mp3`,
      1,
      false
    );
  }

  playHelpAudio(){
   
    this._soundService.playFile(
      `${environment.CDN_URL}/student/audio/F79668A719AB2E440419D3F2E15090ABA471B632FC99BA5BFFD5B504B6494DE5.mp3`,
      1,
      false
    );
  }

  playIncorrectInput(){
    let audioFile = '';
    if(this.incorrectDialogHeader === "teacher passcode"){
      audioFile = '60AD4D1F0A8B84F2C515D899ECCD0FF7B78F03EA2970B0CEB5DD4AB2C8E58683.mp3';
    }else if(this.incorrectDialogHeader === "initials"){
      audioFile = 'CBD9BFCA6F17A93D47998C5B84CDD00A0F62FFC3199DCAC7586301DD47F178A4.mp3';
    }else if(this.incorrectDialogHeader === 'password'){
      audioFile = 'AD5B95E12996C6486823E992414F795989F7CB87DDA78D959F4969E808DFE861.mp3';
    }
   
    this._soundService.playFile(
      `${environment.CDN_URL}/student/audio/${audioFile}`,
      1,
      false
    );
  }

  private checkForInvalidCleverLogin(): void {
    if (this._route.snapshot.queryParams['error'] === 'invalid_clever_login') {
      this.showInvalidCleverLoginDialog = true;
    }
  }

  closeHelpDialog(){
    if(this._soundService.soundIsPlaying$.value){
      this._soundService.stopExpression();
    }
    this.showHelpDialog = false;
  }

  closeIncorrectDialog(){
    if(this._soundService.soundIsPlaying$.value){
      this._soundService.stopExpression();
    }
    this.showIncorrectDialog = false;
    this.incorrectDialogHeader = '';
  }
}
