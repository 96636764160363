import { Component, DestroyRef, EventEmitter, inject } from '@angular/core';
import { SoundService } from '@app/shared/services/sound.service';
import { AnimationHelpers } from '@app/shared/helpers/animation';
import { AnimationBurst } from '@app/shared/services/animation-burst.service';
import { AppStateService } from '@app/shared/services/app-state.service';
import { IPuzzleCompleteEvent } from '@app/shared/types/puzzle-complete-event-interface';
import { PUZZLE_STATUS } from '@app/shared/constants/session-enums';
import { ScoringService } from '@app/shared/services/scoring.service';
import { ActivitySessionService } from '@app/shared/services/activity-session.service';
import { IMistake, IPuzzle } from '@app/shared/types/activity-session-interface';
import { KEY_EVENTS } from '@app/shared/constants/key-event-enums';

@Component({
  selector: 'app-puzzle-type-base',
  template: ``,
  styles: [],
  standalone: true,
  providers: [AppStateService],
})
export abstract class PuzzleTypeBaseComponent {
  protected burst = inject(AnimationBurst);
  protected soundService = inject(SoundService);
  protected appStateService = inject(AppStateService);
  protected destroyRef = inject(DestroyRef);
  protected anim: AnimationHelpers = new AnimationHelpers();

  // scoring
  protected numTries = 0;
  protected validatingPuzzle: boolean;
  protected emitCall: EventEmitter<IPuzzleCompleteEvent>;
  protected _selfCorrected = false;

  private _expressions: Map<number, string>;
  private _scoringService = inject(ScoringService);
  private _sessionService = inject(ActivitySessionService);

  // Derived Classes must implement these.
  abstract initPuzzle();

  constructor() {}

  setExpressions(expressions: Map<number, string>) {
    this._expressions = expressions;
  }

  getExpression(key: number) {
    return this._expressions.get(key);
  }

  hasTriesExceeded() {
    this.numTries += 1;
    this._scoringService.setNumTries(this.numTries);
    return this.numTries >= this.maxTries;
  }

  get isMaxTriedReached() {
    return this.numTries >= this.maxTries;
  }

  get maxTries() {
    return this._scoringService.maxTries;
  }

  resetTries() {
    this.numTries = 0;
    this._scoringService.setNumTries(this.numTries);
  }

  setMaxTries(dataset: any) {
    this._scoringService.setMaxTries(dataset);
  }

  setPerfectScore(score: number) {
    this._scoringService.setPerfectScore(score);
  }

  addMistake() {
    this._scoringService.addMistake();
  }

  addUserMistakeDetails(mistake: IMistake) {
    this._scoringService.addUserMistakeDetails(mistake);
  }

  resetMistakes() {
    this._scoringService.resetMistakes();
  }

  startPuzzleMetrics() {
    this.resetTries();
    this.resetSelfCorrected();
    this._scoringService.startPuzzle(this._sessionService.getPuzzleCount());
  }

  endPuzzleMetrics(result: PUZZLE_STATUS) {
    const puzzle: IPuzzle = this._scoringService.endPuzzle(result);
    if (puzzle) {
      this._sessionService.addPuzzle(puzzle);
    }
  }

  subpartSelfCorrected(selfCorrected: boolean) {
    this._selfCorrected = selfCorrected;
    this._scoringService.setSelfCorrected(this._selfCorrected);
  }

  resetSelfCorrected() {
    this._selfCorrected = false;
    this._scoringService.resetSelfCorrected();
  }

  completePuzzle(puzzleStatus: PUZZLE_STATUS, data?: string): void {
    this.endPuzzleMetrics(puzzleStatus);
    const pComplete: IPuzzleCompleteEvent = {
      status: puzzleStatus,
      data: data,
    };
    this.emitCall.emit(pComplete);
  }

  onArrowKey(event: KeyboardEvent) {
    const el = event.target as HTMLElement;
    if (el) {
      switch (event.key) {
        case KEY_EVENTS.ArrowDown:
        case KEY_EVENTS.ArrowRight:
          (el.nextSibling as HTMLElement)?.focus();
          break;
        case KEY_EVENTS.ArrowUp:
        case KEY_EVENTS.ArrowLeft:
          (el.previousSibling as HTMLElement)?.focus();
          break;
      }
    }
  }
}
