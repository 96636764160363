<div id="pt1">
  <ng-container class="active-word-area" *ngIf="!showChain">
    <div class="active-word grid grid-nogutter" id="active-word">
      <div *ngFor="let letter of letterPanelsActiveWord" class="active-letter">
        <app-letter-panel-component [letterPanel]="letter" (chosenPanel)="onLetterChoiceSelected($event)"></app-letter-panel-component>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="showChain">
    <div class="chain-result">
      <div class="gap-2 col-6 result-container">
        <app-letter-panel-component *ngFor="let letter of formatChain[0]" [letterPanel]="letter"></app-letter-panel-component>
      </div>
      <div class="gap-2 col-6 result-container">
        <app-letter-panel-component *ngFor="let letter of formatChain[1]" [letterPanel]="letter"></app-letter-panel-component>
      </div>
      <div class="gap-2 col-6 result-container">
        <app-letter-panel-component *ngFor="let letter of formatChain[2]" [letterPanel]="letter"></app-letter-panel-component>
      </div>
      <div class="gap-2 col-6 result-container">
        <app-letter-panel-component *ngFor="let letter of formatChain[3]" [letterPanel]="letter"></app-letter-panel-component>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="showChain && formatChain.length > 0">
    <img
      id="proceedButton"
      [ngSrc]="rightButtonImage"
      height="24"
      width="39"
      alt="right arrow"
      class="arrow"
      (click)="resumePuzzle()"
      pTooltip="Proceed"
    />
  </ng-container>
</div>
