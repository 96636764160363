export enum ANIMATIONS {
  Bounce = 'bounce',
  Flash = 'flash',
  Pulse = 'pulse',
  RubberBand = 'rubberBand',
  ShakeX = 'shakeX',
  ShakeY = 'shakeY',
  Swing = 'swing',
  Tada = 'tada',
  Wobble = 'wobble',
  Jello = 'jello',
  BackInLeft = 'backInLeft',
  SpeedInLeft = 'lightSpeedInLeft',
  SpeedInRight = 'lightSpeedInRight',
  SpeedOutRight = 'lightSpeedOutRight',
  FlipInX = 'flipInX',
  HeartBeat = 'heartBeat',
  ZoomOut = 'zoomOut',
  BackInDown = 'backInDown',
  FadeIn = 'fadeIn',
  FadeOut = 'fadeOut',
  ZoomInDown = 'zoomInDown',
  ZoomOutDown = 'zoomOutDown',
  JackInTheBox = 'jackInTheBox',
  BackOutDown = 'backOutDown',
  BackOutUp = 'backOutUp',
  BounceInDown = 'bounceInDown',
  BounceInUp = 'bounceInUp',
}
